'use client';

import { motion } from 'framer-motion';
import { Activity, Clock, Globe, Lock, Mic, Percent } from 'lucide-react';
import React from 'react';
import { cn } from '@/components/lib/utils';
import { BentoGrid, BentoGridItem } from '@/components/ui/bento-grid';

export function WhyWeStandOut() {
  return (
    <div className='w-full flex flex-col items-center justify-center px-6 pt-8'>
      <h4 className='text-3xl lg:text-5xl whitespace-nowrap lg:leading-tight tracking-tight font-medium text-black dark:text-white'>
        Why We Stand Out
      </h4>
      <BentoGrid className='max-w-4xl mt-4'>
        {items.map((item, i) => (
          <BentoGridItem
            key={i}
            title={item.title}
            description={item.description}
            header={item.header}
            icon={item.icon}
            className={cn(
              i === 0 || i === 3 ? 'md:col-span-2' : '',
              i === 1 || i === 2 ? 'md:col-span-1' : '',
              'overflow-hidden',
            )}
          />
        ))}
      </BentoGrid>
    </div>
  );
}

const RealtimeAnimation = () => {
  const pulseVariants = {
    initial: { scale: 0.8, opacity: 0.5 },
    animate: {
      scale: [0.8, 1.2, 0.8],
      opacity: [0.5, 1, 0.5],
      transition: {
        duration: 2,
        repeat: Infinity,
      },
    },
  };

  const dataPointVariants = {
    initial: { x: -100, opacity: 0 },
    animate: { x: 400, opacity: [0, 1, 0] },
  };

  return (
    <motion.div className='flex flex-1 w-full h-full min-h-[6rem] dark:bg-dot-white/[0.2] bg-dot-black/[0.2] relative overflow-hidden'>
      {Array.from({ length: 8 }).map((_, i) => (
        <motion.div
          key={`grid-${i}`}
          className='absolute w-full h-px bg-blue-500/20'
          style={{ top: `${(i + 1) * 12.5}%` }}
        />
      ))}

      {Array.from({ length: 5 }).map((_, i) => (
        <motion.div
          key={`data-${i}`}
          className='absolute flex items-center'
          initial='initial'
          animate='animate'
          variants={dataPointVariants}
          transition={{
            duration: 3,
            repeat: Infinity,
            delay: i * 0.6,
          }}
        >
          <Activity className='h-4 w-4 text-blue-500' />
          <motion.div
            className='h-px w-12 bg-blue-500'
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            transition={{ duration: 0.2 }}
          />
        </motion.div>
      ))}

      <div className='absolute inset-0 flex items-center justify-center'>
        <motion.div
          initial='initial'
          animate='animate'
          variants={pulseVariants}
          className='relative'
        >
          <div className='absolute -inset-4 bg-blue-500/20 rounded-full' />
          <div className='relative bg-blue-500/30 p-6 rounded-full'>
            <Mic className='h-12 w-12 text-blue-500' />
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

// Updated AccuracyAnimation with dropping high percentages
const AccuracyAnimation = () => {
  const percentages = [98, 96, 95, 93, 91];

  return (
    <motion.div className='flex flex-1 w-full h-full min-h-[6rem] dark:bg-dot-white/[0.2] bg-dot-black/[0.2] relative p-4'>
      <div className='absolute inset-0 flex items-center justify-center'>
        {percentages.map((percentage, i) => (
          <motion.div
            key={i}
            className='absolute text-2xl font-bold text-green-500'
            initial={{ opacity: 0, y: -50 }}
            animate={{
              opacity: [0, 1, 0],
              y: [0, 100],
              scale: [0.5, 1.2, 0.8],
            }}
            transition={{
              duration: 3,
              delay: i * 0.8,
              repeat: Infinity,
              ease: 'easeOut',
            }}
          >
            {percentage}%
          </motion.div>
        ))}
        <motion.div
          className='text-4xl font-bold text-green-600'
          animate={{
            scale: [1, 1.1, 1],
            opacity: [0.8, 1, 0.8],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
          }}
        >
          99.9%
        </motion.div>
      </div>
    </motion.div>
  );
};

// Updated SecurityAnimation with blockchain visualization
const SecurityAnimation = () => {
  const nodes = [
    { x: 10, y: 10 },
    { x: 30, y: 25 },
    { x: 50, y: 10 },
    { x: 70, y: 25 },
    { x: 90, y: 10 },
    { x: 10, y: 75 },
    { x: 30, y: 90 },
    { x: 50, y: 75 },
    { x: 70, y: 90 },
    { x: 90, y: 75 },
  ];

  return (
    <motion.div className='flex flex-1 w-full h-full min-h-[6rem] dark:bg-dot-white/[0.2] bg-dot-black/[0.2] relative overflow-hidden'>
      {/* Connection lines */}
      {nodes.map((node, i) =>
        nodes.slice(i + 1).map((target, j) => (
          <motion.div
            key={`line-${i}-${j}`}
            className='absolute h-px bg-red-400/30'
            style={{
              left: `${node.x}%`,
              top: `${node.y}%`,
              width: `${Math.sqrt(Math.pow(target.x - node.x, 2) + Math.pow(target.y - node.y, 2))}%`,
              transform: `rotate(${Math.atan2(target.y - node.y, target.x - node.x)}rad)`,
              transformOrigin: '0 0',
            }}
            animate={{
              opacity: [0.2, 0.5, 0.2],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              delay: (i + j) * 0.2,
            }}
          />
        )),
      )}

      {/* Nodes */}
      {nodes.map((node, i) => (
        <motion.div
          key={`node-${i}`}
          className='absolute'
          style={{
            left: `${node.x}%`,
            top: `${node.y}%`,
            transform: 'translate(-50%, -50%)',
          }}
          animate={{
            scale: [1, 1.2, 1],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            delay: i * 0.2,
          }}
        >
          <div className='h-3 w-3 rounded-full bg-red-400/30 flex items-center justify-center'>
            <div className='h-1.5 w-1.5 rounded-full bg-red-400' />
          </div>
        </motion.div>
      ))}

      {/* Central Lock */}
      <div className='absolute inset-0 flex items-center justify-center'>
        <motion.div
          animate={{
            scale: [1, 1.1, 1],
            rotate: [0, 5, -5, 0],
          }}
          transition={{
            duration: 3,
            repeat: Infinity,
          }}
        >
          <Lock className='h-12 w-12 text-red-400' />
        </motion.div>
      </div>

      {/* Floating shields */}
      {/* {Array.from({ length: 3 }).map((_, i) => (
        <motion.div
          key={`shield-${i}`}
          className='absolute'
          initial={{
            x: '0%',
            y: 20 + i * 30,
          }}
          animate={{
            x: ['0%', '100%'],
            y: [20 + i * 30, 50, 20 + i * 30],
          }}
          transition={{
            duration: 5,
            repeat: Infinity,
            delay: i * 1.5,
          }}
        >
          <Shield className='h-4 w-4 text-purple-500/40' />
        </motion.div>
      ))} */}
    </motion.div>
  );
};

// Updated MultilingualAnimation with globe and flowing languages
const MultilingualAnimation = () => {
  const languages = [
    { text: 'हिंदी', size: 'text-lg' },
    { text: 'English', size: 'text-xl' },
    { text: 'తెలుగు', size: 'text-lg' },
    { text: 'தமிழ்', size: 'text-xl' },
    { text: 'ಕನ್ನಡ', size: 'text-lg' },
    { text: 'മലയാളം', size: 'text-base' },
    { text: 'বাংলা', size: 'text-lg' },
    { text: 'العربية', size: 'text-base' },
    { text: 'मराठी', size: 'text-xl' },
    { text: 'ਪੰਜਾਬੀ', size: 'text-lg' },
    { text: 'اردو', size: 'text-xl' },
    { text: 'français', size: 'text-lg' },
    { text: 'español', size: 'text-base' },
    { text: '中文', size: 'text-xl' },
    { text: '日本語', size: 'text-lg' },
  ];

  return (
    <motion.div className='flex flex-1 w-full h-full min-h-[6rem] dark:bg-dot-white/[0.2] bg-dot-black/[0.2] relative overflow-hidden'>
      {languages.map((lang, i) => {
        const x = (i % 5) * 25; // 5 columns
        const y = Math.floor(i / 5) * 33; // 3 rows

        return (
          <motion.div
            key={lang.text}
            className={cn(
              'absolute font-bold whitespace-nowrap w-full h-full',
              lang.size,
              i % 3 === 0
                ? 'text-blue-500'
                : i % 3 === 1
                  ? 'text-orange-500'
                  : 'text-green-500',
            )}
            initial={{
              x: `${x}%`,
              y: `${y}%`,
              opacity: 0,
            }}
            animate={{
              opacity: [0, 1, 0],
              scale: [0.8, 1.2, 0.8],
              x: [`${x}%`, `${x + 5}%`, `${x}%`],
              y: [`${y}%`, `${y + 5}%`, `${y}%`],
            }}
            transition={{
              duration: 3,
              repeat: Infinity,
              delay: i * 0.2,
              ease: 'easeInOut',
            }}
          >
            {lang.text}
          </motion.div>
        );
      })}
    </motion.div>
  );
};

const items = [
  {
    title: 'Our AI works in Real-Time',
    description: 'Real-time Ambient AI for Clinical Workflows',
    header: <RealtimeAnimation />,
    icon: <Clock className='h-4 w-4 text-neutral-500' />,
  },
  {
    title: 'Unparalleled Accuracy',
    description: 'High Accuracy Medical Transcription Using AI/NLP',
    header: <AccuracyAnimation />,
    icon: <Percent className='h-4 w-4 text-neutral-500' />,
  },
  {
    title: 'Secure like no other',
    description: 'Tamper-proof Data Security with Blockchain',
    header: <SecurityAnimation />,
    icon: <Lock className='h-4 w-4 text-neutral-500' />,
  },
  {
    title: 'Scalibility without Language Barrier',
    description:
      'Tailored Multilingual Support for Regional & Global Languages',
    header: <MultilingualAnimation />,
    icon: <Globe className='h-4 w-4 text-neutral-500' />,
  },
];

export default WhyWeStandOut;
