'use client';

import { Testimonial } from '@/components/ui/testimonial-card';

export function Testimonials() {
  const testimonials = [
    {
      name: 'Dr. Nikita Agarwal',
      role: 'Dermatologist',
      rating: 5,
      image: '/testimonials/nikita-agarwal.jpeg',
      testimonial:
        "Chikitsa.io's history mapping and voice EMR features have been incredibly beneficial in my practice. This integration has significantly improved my workflow, saving time and enhancing patient care.",
    },
    {
      name: 'Mr. Deepak Gupta',
      role: 'CEO at Srishti Hospital',
      rating: 5,
      image: '/testimonials/deepak-gupta.jpeg',
      testimonial:
        "Implementing Chikitsa.io has been a game-changer for us. It seamlessly integrates digital health solutions, enhancing patient engagement, streamlining workflows, and improving efficiency. With its user-friendly interface and robust analytics, we've optimized patient care while reducing administrative burdens. Chikitsa.io has truly transformed our digital health journey.",
    },
    {
      name: 'Dr. Mukesh Gupta',
      role: 'General Physician',
      rating: 5,
      image: '/testimonials/mukesh-gupta.jpeg',
      testimonial:
        'Chikitsa.io has made digitalizing medical prescriptions effortless. With its intuitive interface and seamless integration, prescribing and managing patient records has never been easier. A true innovation in digital healthcare!',
    },
    {
      name: 'Mr. Amit Mansingh',
      role: 'CEO at TTP Health',
      rating: 5,
      image: '/testimonials/amit-mansingh.jpeg',
      testimonial:
        'चिकित्सा, powered by AI, plays a pivotal role in realizing our vision. It enhances decision-making, optimizes workflows, and boosts financial performance, enabling us to provide efficient, patient-centered care. This technology is setting new standards in healthcare, perfectly aligning with our commitment to innovation and excellence.',
    },
  ];

  return (
    <div className='w-full flex flex-col items-center justify-center'>
      <div className='pt-9'>
        <h4 className='text-3xl lg:text-5xl lg:leading-tight max-w-5xl mx-auto text-center tracking-tight font-medium text-black dark:text-white'>
          Testimonials
        </h4>

        <p className='text-sm lg:text-base  max-w-2xl px-6  mt-1 mb-4 text-neutral-500 text-center font-normal dark:text-neutral-300'>
          Trusted by healthcare professionals and organizations Worldwide.
        </p>
      </div>
      <div className='container px-6 max-w-4xl'>
        <div className='grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-2'>
          {testimonials.map((testimonial) => (
            <Testimonial key={testimonial.name} {...testimonial} />
          ))}
        </div>
      </div>
    </div>
  );
}
