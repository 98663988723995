'use client';

import {
  Facebook,
  Instagram,
  Linkedin,
  MapPin,
  Moon,
  Sun,
  Twitter,
} from 'lucide-react';
import * as React from 'react';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { CookiesPolicy } from './CookiesPolicy';
import { PrivacyPolicy } from './PrivacyPolicy';
import { TermsAndConditions } from './TermsAndConditions';

export const Footer = () => {
  const [isDarkMode, setIsDarkMode] = React.useState(false);

  React.useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  return (
    <footer className='relative border-t bg-background text-foreground transition-colors duration-300'>
      <div className='container mx-auto px-4 py-12 md:px-6 lg:px-8'>
        <div className='grid gap-12 md:grid-cols-2 lg:grid-cols-4'>
          <div className='relative'>
            <h2 className='mb-4 text-3xl font-bold tracking-tight'>Chikitsa</h2>
            <p className='mb-6 text-muted-foreground'>
              Chikitsa.io is all about transforming healthcare efficiency and
              accuracy. We leverage cutting-edge technologies like AI, NLP, and
              blockchain to automate clinical documentation, enhance data
              security, and streamline medical workflows. Our goal is to reduce
              medical errors, improve patient care, and enable seamless
              interoperability between healthcare providers, ensuring a smarter
              and more efficient healthcare ecosystem.
            </p>
            {/* <form className='relative'>
              <Input
                type='email'
                placeholder='Enter your email'
                className='pr-12 backdrop-blur-sm'
              />
              <Button
                type='submit'
                size='icon'
                className='absolute right-1 top-1 h-8 w-8 rounded-full bg-primary text-primary-foreground transition-transform hover:scale-105'
              >
                <Send className='h-4 w-4' />
                <span className='sr-only'>Subscribe</span>
              </Button>
            </form> */}
            <div className='absolute -right-4 top-0 h-24 w-24 rounded-full bg-primary/10 blur-2xl' />
          </div>
          <div>
            <h3 className='mb-4 text-lg font-semibold'>Quick Links</h3>
            <nav className='space-y-2 text-sm'>
              <a
                href='#'
                className='block transition-colors hover:text-primary'
              >
                Home
              </a>
              <a
                href='#services'
                className='block transition-colors hover:text-primary'
              >
                Services
              </a>
              <a
                href='#features'
                className='block transition-colors hover:text-primary'
              >
                Features
              </a>
              <a
                href='#testimonials'
                className='block transition-colors hover:text-primary'
              >
                Testimonials
              </a>
              <a
                href='#contact'
                className='block transition-colors hover:text-primary'
              >
                Contact
              </a>
            </nav>
          </div>
          <div>
            <h3 className='mb-4 text-lg font-semibold'>Contact Us</h3>
            <address className='space-y-2 text-sm not-italic'>
              <p className='font-semibold flex items-center gap-1'>
                <MapPin className='w-4 h-4' /> Software Technology Parks of
                India
              </p>
              <a
                href='https://www.google.com/maps/search/?api=1&query=Software+Technology+Parks+of+India+Plot+No+P1+MIDC+Hinjewadi+Phase+1+Rd+Hinjewadi+Rajiv+Gandhi+Infotech+Park+Pune+Maharashtra+411057'
                target='_blank'
                rel='noopener noreferrer'
                className='block hover:text-primary'
              >
                Plot No P1, MIDC, Hinjewadi Phase 1 Rd, Hinjewadi Rajiv Gandhi
                Infotech Park, Pune, Maharashtra 411057
              </a>
              <p className='font-semibold flex items-center gap-1'>
                <MapPin className='w-4 h-4' />
                Bhamashah Techno Hub
              </p>
              <a
                href='https://www.google.com/maps/search/?api=1&query=Bhamashah+Techno+Hub+4th+Floor+Sansthan+Path+Jhalana+Gram+Malviya+Nagar+Jaipur+Rajasthan+302017'
                target='_blank'
                rel='noopener noreferrer'
                className='block hover:text-primary'
              >
                4th Floor, Bhamashah Techno Hub, Sansthan Path, Jhalana Gram,
                Malviya Nagar, Jaipur, Rajasthan 302017
              </a>
              <p className='font-semibold'>
                Phone:{' '}
                <a
                  href='tel:+919509917266'
                  className='hover:text-primary hover:underline'
                >
                  +91 9509917266
                </a>
              </p>
              <p className='font-semibold'>
                Email:{' '}
                <a
                  href='mailto:connect@chikitsa.io'
                  className='hover:text-primary hover:underline'
                >
                  connect@chikitsa.io
                </a>
              </p>
            </address>
          </div>
          <div className='relative'>
            <h3 className='mb-4 text-lg font-semibold'>Follow Us</h3>
            <div className='mb-6 flex space-x-4'>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant='outline'
                      size='icon'
                      className='rounded-full'
                      onClick={() => {
                        window.open(
                          'https://www.facebook.com/chikitsahealth',
                          '_blank',
                        );
                      }}
                    >
                      <Facebook className='h-4 w-4' />
                      <span className='sr-only'>Facebook</span>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Follow us on Facebook</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant='outline'
                      size='icon'
                      className='rounded-full'
                      onClick={() => {
                        window.open('https://x.com/chikitsahealth', '_blank');
                      }}
                    >
                      <Twitter className='h-4 w-4' />
                      <span className='sr-only'>Twitter</span>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Follow us on Twitter</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant='outline'
                      size='icon'
                      className='rounded-full'
                      onClick={() => {
                        window.open(
                          'https://www.instagram.com/chikitsa.io/',
                          '_blank',
                        );
                      }}
                    >
                      <Instagram className='h-4 w-4' />
                      <span className='sr-only'>Instagram</span>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Follow us on Instagram</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant='outline'
                      size='icon'
                      className='rounded-full'
                      onClick={() => {
                        window.open(
                          'https://www.linkedin.com/company/vigorus-healthtech-private-limited-chikitsa/?originalSubdomain=in',
                          '_blank',
                        );
                      }}
                    >
                      <Linkedin className='h-4 w-4' />
                      <span className='sr-only'>LinkedIn</span>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Connect with us on LinkedIn</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className='flex items-center space-x-2'>
              <Sun className='h-4 w-4' />
              <Switch
                id='dark-mode'
                checked={isDarkMode}
                onCheckedChange={setIsDarkMode}
              />
              <Moon className='h-4 w-4' />
              <Label htmlFor='dark-mode' className='sr-only'>
                Toggle dark mode
              </Label>
            </div>
          </div>
        </div>
        <div className='mt-12 flex flex-col items-center justify-between gap-4 border-t pt-8 text-center md:flex-row'>
          <p className='text-sm text-muted-foreground'>
            © 2025 Chikitsa. All Rights Reserved.
          </p>
          <nav className='flex gap-4 text-sm'>
            <PrivacyPolicy />
            <TermsAndConditions />
            <CookiesPolicy />
          </nav>
        </div>
      </div>
    </footer>
  );
};
