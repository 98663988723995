'use client';

import {
  animate,
  motion,
  useMotionTemplate,
  useMotionValue,
} from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import React, { useEffect } from 'react';
import { SparklesCore } from '@/components/ui/sparkles';
import { Spotlight } from '@/components/ui/spotlight-new';

// const COLORS_TOP = ['#13FFAA', '#1E67C6', '#CE84CF', '#DD335C'];
const COLORS_TOP = ['rgba(123, 11, 255, 1)', 'rgba(1, 209, 255, 1)'];

export const Hero = () => {
  const color = useMotionValue(COLORS_TOP[0]);

  useEffect(() => {
    animate(color, COLORS_TOP, {
      ease: 'easeInOut',
      duration: 10,
      repeat: Infinity,
      repeatType: 'mirror',
    });
  }, [color]);

  const gradientOverlay = useMotionTemplate`radial-gradient(140% 140% at 50% 110%, transparent 50%, ${color})`;

  const border = useMotionTemplate`1px solid ${color}`;
  const boxShadow = useMotionTemplate`0px 4px 24px ${color}`;

  return (
    <div className='relative w-full'>
      <motion.section
        style={{
          backgroundImage: gradientOverlay,
        }}
        className='relative grid min-h-screen place-content-center overflow-hidden px-4 py-24'
      >
        <div className='relative z-10 flex flex-col items-center'>
          <Spotlight />
          <span className='mb-1.5 inline-block rounded-full bg-transparent/10 px-3 py-1.5 text-sm'>
            Shaping the Future
          </span>
          <h1 className='max-w-6xl bg-gradient-to-br from-foreground to-muted-foreground bg-clip-text text-center text-3xl font-medium leading-tight text-transparent sm:text-5xl sm:leading-tight md:text-7xl md:leading-tight'>
            AI Agent: Listens, Learns,
            <br />
            And Transforms Care
          </h1>
          <p className='my-6 max-w-xl text-center text-base leading-relaxed text-muted-foreground md:text-lg md:leading-relaxed'>
            Revolutionizing Healthcare with AI Agents for Clinicians, Hospitals,
            and Innovators.
          </p>
          <motion.button
            style={{
              border,
              boxShadow,
            }}
            whileHover={{
              scale: 1.015,
            }}
            whileTap={{
              scale: 0.985,
            }}
            className='group relative flex w-fit items-center gap-1.5 rounded-full bg-background/10 px-4 py-2 transition-colors hover:bg-background/20'
            onClick={() => {
              window.open(
                'https://outlook.office365.com/book/ScheduleaDemo@vigorushealth.com/',
                '_blank',
              );
            }}
          >
            Book your Demo
            <ArrowRight className='transition-transform group-hover:-rotate-45 group-active:-rotate-12 size-4' />
          </motion.button>
        </div>
      </motion.section>
      <div className='absolute inset-0'>
        <SparklesCore
          background='transparent'
          minSize={0.1}
          maxSize={0.7}
          particleDensity={400}
          className='absolute inset-0'
          particleColor={'#FFFFFF'}
        />
        <div className='absolute bg-black [mask-image:radial-gradient(350px_200px_at_top,transparent_20%,white)]'></div>
      </div>
    </div>
  );
};

export default Hero;
