'use client';

import { useRef, useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';

export const CookiesPolicy = () => {
  const [hasReadToBottom, setHasReadToBottom] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const content = contentRef.current;
    if (!content) return;

    const scrollPercentage =
      content.scrollTop / (content.scrollHeight - content.clientHeight);
    if (scrollPercentage >= 0.99 && !hasReadToBottom) {
      setHasReadToBottom(true);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant='link' className='transition-colors hover:text-primary'>
          Cookies Policy
        </Button>
      </DialogTrigger>
      <DialogContent className='flex flex-col gap-0 p-0 sm:max-h-[min(640px,80vh)] sm:max-w-lg [&>button:last-child]:top-3.5'>
        <DialogHeader className='contents space-y-0 text-left'>
          <DialogTitle className='border-b border-border px-6 py-4 text-base'>
            Cookies Policy
          </DialogTitle>
          <div
            ref={contentRef}
            onScroll={handleScroll}
            className='overflow-y-auto'
          >
            <DialogDescription asChild>
              <div className='px-6 py-4 space-y-6 text-sm'>
                <div className='text-xs text-muted-foreground'>
                  Last Updated: February 9, 2025
                </div>

                <p className='leading-relaxed'>
                  Please read this Cookies Policy carefully as it contains
                  important information about who we are and how we use cookies
                  on our website. This policy should be read in conjunction with
                  our Privacy Policy, which explains how we collect, store, use,
                  and share personal information, your rights regarding your
                  personal data, and how to contact us if you have any concerns.
                </p>

                <section className='space-y-2'>
                  <h3 className='font-semibold'>Who We Are</h3>
                  <p className='leading-relaxed'>
                    This website is operated by Chikitsa.io, a health-tech
                    company that provides AI-powered healthcare solutions,
                    including Smart Hospital Information Management Systems
                    (HIMS), Electronic Medical Records (EMR), and digital health
                    innovations to streamline patient care. Chikitsa.io also
                    offers healthcare AI agents such as medical speech-to-text,
                    health claim exchange, and other AI-driven healthcare
                    automation tools.
                  </p>
                </section>

                <section className='space-y-2'>
                  <h3 className='font-semibold'>Our Website</h3>
                  <p className='leading-relaxed'>
                    This Cookies Policy applies only to your use of our website.
                    We may link to third-party websites that are not operated by
                    us. These external websites may also use cookies or similar
                    technologies as per their respective policies. Please review
                    their policies for more details.
                  </p>
                </section>

                <section className='space-y-2'>
                  <h3 className='font-semibold'>Cookies</h3>
                  <p className='leading-relaxed'>
                    A cookie is a small text file placed on your device (e.g.,
                    computer, smartphone, or tablet) when you visit our website.
                    We use cookies to enhance your experience and improve our
                    services.
                  </p>
                  <div className='pl-4 space-y-2'>
                    <p>We use the following types of cookies:</p>
                    <ul className='list-disc pl-4 space-y-1'>
                      <li>
                        Strictly Necessary Cookies – Essential for the
                        website&apos;s core functionality, such as secure logins
                        and transaction processing.
                      </li>
                      <li>
                        Analytical or Performance Cookies – Help us analyze
                        visitor behavior and improve our website&apos;s
                        usability.
                      </li>
                      <li>
                        Functionality Cookies – Remember your preferences and
                        personalize content for a better experience.
                      </li>
                      <li>
                        Advertisement Cookies – Track your website visits to
                        tailor advertisements and promotional messages.
                      </li>
                    </ul>
                  </div>
                </section>

                <section className='space-y-2'>
                  <h3 className='font-semibold'>Our Use of Cookies</h3>
                  <div className='overflow-x-auto'>
                    <table className='min-w-full border border-border'>
                      <thead>
                        <tr className='bg-muted/50'>
                          <th className='px-4 py-2 border-r border-border text-left'>
                            Name
                          </th>
                          <th className='px-4 py-2 border-r border-border text-left'>
                            Purpose
                          </th>
                          <th className='px-4 py-2 border-r border-border text-left'>
                            Type
                          </th>
                          <th className='px-4 py-2 text-left'>Duration</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className='px-4 py-2 border-r border-border'>
                            Google Analytics
                          </td>
                          <td className='px-4 py-2 border-r border-border'>
                            Tracks user behavior to enhance website performance
                          </td>
                          <td className='px-4 py-2 border-r border-border'>
                            Analytics
                          </td>
                          <td className='px-4 py-2'>1 year</td>
                        </tr>
                        <tr className='bg-muted/20'>
                          <td className='px-4 py-2 border-r border-border'>
                            Amplitude
                          </td>
                          <td className='px-4 py-2 border-r border-border'>
                            Understands user interaction to optimize our
                            services
                          </td>
                          <td className='px-4 py-2 border-r border-border'>
                            Analytics
                          </td>
                          <td className='px-4 py-2'>1 year</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>

                <section className='space-y-2'>
                  <h3 className='font-semibold'>How to Contact Us</h3>
                  <p className='leading-relaxed'>
                    If you have any questions regarding this Cookies Policy or
                    how we use cookies, please contact us at:
                  </p>
                  <p className='pl-4'>
                    📧 Email:{' '}
                    <a
                      href='mailto:connect@chikitsa.io'
                      className='text-primary hover:underline'
                    >
                      connect@chikitsa.io
                    </a>
                  </p>
                </section>

                <section className='space-y-2'>
                  <h3 className='font-semibold'>Changes to This Policy</h3>
                  <p className='leading-relaxed'>
                    We may update this policy periodically. When we make
                    significant changes, we will notify you via email if you are
                    registered with us.
                  </p>
                </section>

                <p className='italic'>
                  By using our website, you agree to our use of cookies as
                  outlined in this policy.
                </p>
              </div>
            </DialogDescription>
          </div>
        </DialogHeader>
        <DialogFooter className='border-t border-border px-6 py-4 sm:items-center'>
          {!hasReadToBottom && (
            <span className='grow text-xs text-muted-foreground max-sm:text-center'>
              Read all terms before accepting.
            </span>
          )}
          <DialogClose asChild>
            <Button type='button' variant='outline'>
              Cancel
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button type='button' disabled={!hasReadToBottom}>
              I agree
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
