'use client';

import { AnimatePresence, useMotionValueEvent, useScroll } from 'framer-motion';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { useState } from 'react';
import { cn } from '@/lib/utils';

export const FloatingNav = ({
  navItems,
  className,
}: {
  navItems: {
    name: string;
    link: string;
    icon?: JSX.Element;
  }[];
  className?: string;
}) => {
  const { scrollYProgress } = useScroll();
  const [visible, setVisible] = useState(true);

  useMotionValueEvent(scrollYProgress, 'change', (current) => {
    if (typeof current === 'number') {
      const direction = current! - scrollYProgress.getPrevious()!;

      if (scrollYProgress.get() < 0.05) {
        setVisible(true);
      } else {
        if (direction < 0) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      }
    }
  });

  return (
    <AnimatePresence mode='wait'>
      <motion.div
        initial={{
          opacity: 1,
          y: -100,
        }}
        animate={{
          y: visible ? 0 : -100,
          opacity: visible ? 1 : 0,
        }}
        transition={{
          duration: 0.2,
        }}
        className={cn(
          'flex max-w-fit fixed top-10 inset-x-0 mx-auto rounded-full z-[5000] px-3 pl-4 py-2 items-center justify-center space-x-4',
          // Glass effect for light mode
          'bg-white/70 backdrop-blur-sm backdrop-saturate-250',
          // Glass effect for dark mode
          'dark:bg-black/40 dark:backdrop-blur-sm dark:backdrop-saturate-250',
          // Border and shadow effects
          'border border-white/20 dark:border-white/10',
          'shadow-lg shadow-black/5 dark:shadow-black/10',
          className,
        )}
      >
        {/* <Image
          src='/chikitsa-full-logo.svg'
          alt='Chikitsa Logo'
          width={100}
          height={100}
          className='w-50 h-10'
        /> */}

        {navItems.map(
          (
            navItem: {
              name: string;
              link: string;
              icon?: JSX.Element;
            },
            idx: number,
          ) => (
            <Link
              key={`link=${idx}`}
              href={navItem.link}
              className={cn(
                'relative items-center flex space-x-1',
                'text-gray-700 dark:text-gray-200',
                'hover:text-black dark:hover:text-white',
                'transition-colors duration-200',
              )}
            >
              <span className='block sm:hidden'>{navItem.icon}</span>
              <span className='hidden sm:block text-sm'>{navItem.name}</span>
            </Link>
          ),
        )}

        {/* <button
          onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
          className='p-2 rounded-full hover:bg-white/20 dark:hover:bg-white/10 transition-colors duration-200'
        >
          {theme === 'dark' ? (
            <Sun className='h-4 w-4 text-gray-200' />
          ) : (
            <Moon className='h-4 w-4 text-gray-700' />
          )}
        </button> */}
        <button
          className={cn(
            'relative px-4 py-2 rounded-full text-sm font-medium',
            'bg-white/10 dark:bg-white/5',
            'border border-white/20 dark:border-white/10',
            'text-gray-900 dark:text-white',
            'hover:bg-white/20 dark:hover:bg-white/10',
            'transition-colors duration-200',
          )}
          onClick={() => {
            window.open(
              'https://outlook.office365.com/book/ScheduleaDemo@vigorushealth.com/',
              '_blank',
            );
          }}
        >
          <span>Book Demo</span>
          <span className='absolute inset-x-0 w-1/2 mx-auto -bottom-px bg-gradient-to-r from-transparent via-blue-500 to-transparent h-px' />
        </button>
      </motion.div>
    </AnimatePresence>
  );
};

export default FloatingNav;
