'use client';

import { motion } from 'framer-motion';
import React, { useState } from 'react';

export const ProblemStatement = () => {
  const [activeCard, setActiveCard] = useState(0);

  const circles = Array(4).fill(null);

  const cards = [
    {
      title: 'Hidden Crisis in Clinical Documentation',
      description:
        "Efficient documentation isn't just paperwork—it's about patient safety, time saved, and revenue secured. Are you ready to fix it?",
      points: [
        'Doctors Drowning in Paperwork – Excessive clinical documentation steals time from patient care.',
        'Missing Insights, Rising Errors – Critical health data gets lost in the clutter, leading to misdiagnoses and treatment risks.',
        'Revenue Lost to Rejected Claims – Incomplete records and delayed submissions cause costly claims denial and financial setbacks.',
      ],
      icon: (
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z'
        />
      ),
    },
    {
      title: 'Poor Patient Engagement',
      description:
        "Patient engagement isn't just communication—it's about building trust, improving outcomes, and driving loyalty. Are you ready to transform it?",
      points: [
        'Limited Access to Reliable Information: Patients lack trustworthy resources, delaying timely care.',
        'Difficulty in Symptom Assessment: Struggles in interpreting and describing symptoms cause confusion and delays.',
        'Inefficient Communication: Traditional methods like phone calls and visits lead to frustration and delays.',
        'No 24/7 Support: Patients lack guidance during emergencies or outside clinic hours.',
        'Fragmented Patient Journey: Appointment booking, payments, and reports are scattered across platforms.',
      ],
      icon: (
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z'
        />
      ),
    },
    {
      title: 'Veiled Complexities in Clinical Decision Making',
      description:
        'Unnoticed errors and missed insights are creating risks. Are you prepared to solve them?',
      points: [
        'Diagnostic Errors: Misdiagnoses due to limited access to real-time, evidence-based tools.',
        'Information Overload: An overwhelming influx of medical data hinders timely decision-making.',
        'Time Constraints: Rushed evaluations under tight schedules increase the risk of errors.',
        'Physician Burnout: The cognitive strain of high-stakes decisions impacts performance and well-being.',
      ],
      icon: (
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01'
        />
      ),
    },
  ];

  return (
    <div className='w-full p-6'>
      <motion.div
        className='w-full rounded-2xl bg-gradient-to-br from-indigo-600 via-purple-600 to-pink-600 dark:from-indigo-900 dark:via-purple-900 dark:to-pink-900 p-1'
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className='bg-white dark:bg-gray-900 rounded-2xl p-4'>
          <h2 className='text-3xl font-bold uppercase tracking-wider bg-gradient-to-r from-purple-600 to-pink-600 dark:from-purple-400 dark:to-pink-400 bg-clip-text text-transparent mb-2 text-center'>
            The Problem
          </h2>

          <div className='flex justify-center gap-4 mb-6'>
            {cards.map((_, index) => (
              <button
                key={index}
                onClick={() => setActiveCard(index)}
                className={`w-3 h-3 rounded-full ${activeCard === index ? 'bg-purple-600' : 'bg-gray-300'}`}
              />
            ))}
          </div>

          <div className='flex flex-col lg:flex-row gap-8 items-center'>
            <div className='flex-1 space-y-6'>
              <motion.div
                key={activeCard}
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 50 }}
                transition={{ duration: 0.5 }}
              >
                <h2 className='text-4xl font-bold bg-gradient-to-r from-gray-900 via-gray-700 to-gray-800 dark:from-white dark:via-gray-100 dark:to-gray-300 bg-clip-text text-transparent mb-6'>
                  {cards[activeCard].title}
                </h2>
                <p className='text-gray-700 dark:text-gray-300 leading-relaxed'>
                  {cards[activeCard].description}
                </p>
                <ul className='space-y-3 mt-4'>
                  {cards[activeCard].points.map((text, index) => (
                    <motion.li
                      key={index}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.4 + index * 0.2 }}
                      className='flex items-center text-gray-600 dark:text-gray-400'
                    >
                      <span className='h-1.5 w-1.5 rounded-full bg-purple-500 mr-3' />
                      {text}
                    </motion.li>
                  ))}
                </ul>
              </motion.div>
            </div>

            <div className='relative w-64 h-64 flex items-center justify-center'>
              {circles.map((_, i) => (
                <motion.div
                  key={i}
                  custom={i}
                  variants={{
                    hidden: { scale: 0, opacity: 0 },
                    visible: (i) => ({
                      scale: 1,
                      opacity: 1,
                      transition: {
                        delay: i * 0.2,
                        duration: 0.5,
                        repeat: Infinity,
                        repeatType: 'reverse',
                        repeatDelay: 1,
                      },
                    }),
                  }}
                  initial='hidden'
                  animate='visible'
                  className='absolute'
                  style={{
                    width: `${(i + 1) * 40}px`,
                    height: `${(i + 1) * 40}px`,
                    borderRadius: '50%',
                    border: '2px dashed',
                    borderColor: `rgba(${219 - i * 20}, ${39 + i * 20}, ${119 + i * 20}, ${0.3 + i * 0.1})`,
                  }}
                />
              ))}
              <motion.div
                key={activeCard}
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                className='absolute'
              >
                <svg
                  className='w-16 h-16 text-pink-600 dark:text-pink-400'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  {cards[activeCard].icon}
                </svg>
              </motion.div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};
