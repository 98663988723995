'use client';

import { IconBrandYoutubeFilled } from '@tabler/icons-react';
import { AnimatePresence, motion } from 'framer-motion';
import {
  Activity,
  Brain,
  Check,
  CheckCircle,
  FileText,
  FlaskConical,
  Loader2,
  Mic,
  Monitor,
  Stethoscope,
  Wand,
} from 'lucide-react';
import { Bot, MessageCircle, Plus, User } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { cn } from '@/components/lib/utils';
import { CodeBlock } from '@/components/ui/code-block';

const OurSolutions = () => {
  const features = [
    {
      title: 'For Innovators',
      description:
        'API & SDK in circle which is surrounded by ABDM Bridge, STT, CDSS, Mobile App, Block Chain Solution.',
      skeleton: <SkeletonOne />,
      className:
        'col-span-1 lg:col-span-4 border-b lg:border-r dark:border-neutral-800',
    },

    {
      title: 'For Clinicians',
      description:
        'Clinician either surrounded or using HIMS, Telemedicine, STT, CDSS.',
      skeleton: <Clinicians />,
      className: 'border-b col-span-1 lg:col-span-2 dark:border-neutral-800',
    },
    {
      title: 'For Administrator',
      description: 'Simplify Insurance Claims and Patient Records Management.',
      skeleton: <SkeletonThree />,
      className: 'border-b col-span-1 lg:col-span-6 dark:border-neutral-800',
    },
    {
      title: 'For Patients',
      description: 'Whatsapp Chatbot Chikki AI.',
      skeleton: <Patients />,
      className:
        'col-span-1 lg:col-span-3 lg:border-r  dark:border-neutral-800',
    },
    {
      title: 'For Radiologists',
      description: 'Speech Digitizer with AI reporting tool.',
      skeleton: <AnimatedRadiology />,
      className: 'col-span-1 lg:col-span-3 border-b lg:border-none',
    },
  ];

  return (
    <div className='w-full flex flex-col items-center justify-center'>
      <div className='max-w-4xl mx-6'>
        <div className='relative z-20 py-5 lg:py-10 max-w-7xl mx-auto'>
          <div className='px-2'>
            <h4 className='text-3xl lg:text-5xl lg:leading-tight max-w-5xl mx-auto text-center tracking-tight font-medium text-black dark:text-white'>
              The Solution
            </h4>

            <p className='text-sm lg:text-base  max-w-2xl mx-auto text-neutral-500 text-center font-normal dark:text-neutral-300'>
              We have various products from SDKs to comprehensive Healthcare
              Solutions
            </p>
          </div>

          <div className='relative'>
            <div className='grid grid-cols-1 lg:grid-cols-6 mt-12 xl:border rounded-md dark:border-neutral-800'>
              {features.map((feature) => (
                <FeatureCard key={feature.title} className={feature.className}>
                  <FeatureTitle>{feature.title}</FeatureTitle>
                  <FeatureDescription>{feature.description}</FeatureDescription>
                  <div className=' h-full w-full'>{feature.skeleton}</div>
                </FeatureCard>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurSolutions;

const FeatureCard = ({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn(`p-4 sm:p-8 relative overflow-hidden`, className)}>
      {children}
    </div>
  );
};
const FeatureTitle = ({ children }: { children?: React.ReactNode }) => {
  return (
    <p className=' max-w-5xl mx-auto text-left tracking-tight text-black dark:text-white text-xl md:text-2xl md:leading-snug'>
      {children}
    </p>
  );
};

const FeatureDescription = ({ children }: { children?: React.ReactNode }) => {
  return (
    <p
      className={cn(
        'text-sm md:text-base  max-w-4xl text-left mx-auto',
        'text-neutral-500 text-center font-normal dark:text-neutral-300',
        'text-left max-w-sm mx-0 md:text-sm my-2',
      )}
    >
      {children}
    </p>
  );
};

export const Clinicians = () => {
  const [currentState, setCurrentState] = useState(0);
  const [progress, setProgress] = useState(0);
  const [showDocument, setShowDocument] = useState(false);
  const [completed, setCompleted] = useState(false);

  const states = [
    'Listening...',
    'Processing...',
    'Analyzing...',
    'Generating...',
  ];

  useEffect(() => {
    // Progress bar animation
    const progressInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          setShowDocument(true);
          setTimeout(() => {
            setCompleted(true);
            // Reset everything after completion
            setTimeout(() => {
              setProgress(0);
              setShowDocument(false);
              setCompleted(false);
            }, 2000);
          }, 1000);

          return 0;
        }

        return prev + 1;
      });
    }, 50);

    // State text animation
    const stateInterval = setInterval(() => {
      setCurrentState((prev) => (prev + 1) % states.length);
    }, 3000);

    return () => {
      clearInterval(progressInterval);
      clearInterval(stateInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const waveVariants = {
    initial: { scaleY: 0.5, opacity: 0.3 },
    animate: {
      scaleY: [0.5, 1, 0.5],
      opacity: [0.3, 1, 0.3],
      transition: { repeat: Infinity, duration: 1 },
    },
  };

  return (
    <div className='w-full p-4 min-h-[250px]'>
      <motion.div
        className='flex flex-col items-center space-y-3'
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <motion.div
          className='flex items-center space-x-2'
          animate={{ opacity: [0.5, 1, 0.5] }}
          transition={{ duration: 2, repeat: Infinity }}
        >
          <div className='h-2 w-2 rounded-full bg-green-500 animate-pulse' />
          <p className='text-sm font-medium text-gray-700 dark:text-gray-300'>
            {states[currentState]}
          </p>
        </motion.div>

        <div className='relative w-16 h-16 rounded-full bg-gradient-to-br from-blue-100 to-blue-50 dark:from-blue-900/30 dark:to-blue-800/30 flex items-center justify-center'>
          <Mic className='w-8 h-8 text-blue-600 dark:text-blue-400' />
          <motion.div
            className='absolute inset-0 rounded-full border-2 border-blue-500/50'
            animate={{
              scale: [1, 1.2, 1],
              opacity: [1, 0, 1],
            }}
            transition={{ repeat: Infinity, duration: 2 }}
          />
        </div>

        <AnimatePresence>
          <motion.div
            className='flex space-x-1'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {[...Array(3)].map((_, i) => (
              <motion.div
                key={i}
                className='w-1 bg-blue-500 dark:bg-blue-400 rounded-full'
                style={{ height: 16 }}
                variants={waveVariants}
                initial='initial'
                animate='animate'
                custom={i}
                transition={{ delay: i * 0.1 }}
              />
            ))}
          </motion.div>
        </AnimatePresence>

        <div className='w-48 h-1.5 bg-gray-100 dark:bg-gray-800 rounded-full overflow-hidden'>
          <motion.div
            className='h-full bg-gradient-to-r from-blue-500 to-blue-600 dark:from-blue-400 dark:to-blue-500'
            initial={{ width: 0 }}
            animate={{ width: `${progress}%` }}
            transition={{ duration: 0.3 }}
          />
        </div>

        <AnimatePresence mode='wait'>
          {showDocument && (
            <motion.div
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
              className='relative bg-gradient-to-br from-white to-gray-50 dark:from-neutral-800 dark:to-neutral-900 p-4 rounded-lg shadow-lg border border-gray-100 dark:border-gray-700'
            >
              <div className='flex items-center space-x-3'>
                <Stethoscope className='w-10 h-10 text-blue-600 dark:text-blue-400' />
                <div>
                  <h3 className='text-sm font-medium text-gray-900 dark:text-gray-100'>
                    Prescription Ready
                  </h3>
                  <p className='text-xs text-gray-500 dark:text-gray-400'>
                    AI-Assisted EMR
                  </p>
                </div>
              </div>

              {completed ? (
                <motion.div
                  className='absolute -top-2 -right-2 bg-green-500 rounded-full p-2 shadow-lg'
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ delay: 0.2 }}
                >
                  <Check className='w-4 h-4 text-white' />
                </motion.div>
              ) : (
                <motion.div
                  className='absolute -top-2 -right-2 bg-blue-500 rounded-full p-2 shadow-lg'
                  animate={{ rotate: 360 }}
                  transition={{
                    duration: 1,
                    repeat: Infinity,
                    ease: 'linear',
                  }}
                >
                  <Loader2 className='w-4 h-4 text-white' />
                </motion.div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};
export const SkeletonOne = () => {
  const HTMLLanguage = `<div className="p-4 border rounded-lg">
        <h2 className="text-xl font-bold mb-4">Patient Records</h2>
        <p className="mb-2">Total Records: {records}</p>
        <button 
          onClick={handleView}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          View Details
        </button>
      </div>`;

  const CSSLanguage = `.button {
        background-color: blue;
        color: white;
        padding: 10px 20px;
        border-radius: 5px;
      }

      .card {
        background: white;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        padding: 20px;
      }`;

  return (
    <div className='relative flex py-2 px-2 gap-2 h-full'>
      {/* <div className='w-full  p-5  mx-auto bg-white dark:bg-neutral-900 shadow-xl group h-full'> */}
      <div className='flex flex-1 w-full h-full flex-col space-y-2  '>
        <CodeBlock
          language='jsx'
          filename='DummyComponent.jsx'
          tabs={[
            {
              name: 'DummyComponent.css',
              code: CSSLanguage,
              language: 'css',
              highlightLines: [1, 2, 3],
            },
            {
              name: 'DummyComponent.html',
              code: HTMLLanguage,
              language: 'html',
            },
          ]}
        />
        {/* </div> */}
      </div>

      <div className='absolute bottom-0 z-40 inset-x-0 h-50  from-white dark:from-black via-white dark:via-black to-transparent w-full pointer-events-none' />
      <div className='absolute top-0 z-40 inset-x-0 h-70 from-white dark:from-black via-transparent to-transparent w-full pointer-events-none' />
    </div>
  );
};

export const Patients = () => {
  const [messages, setMessages] = useState([
    {
      type: 'bot',
      text: 'Hello! I can help you check your symptoms. What are you experiencing?',
    },
  ]);
  const [currentSymptom, setCurrentSymptom] = useState('');

  const commonSymptoms = ['Fever', 'Cough', 'Fatigue'];

  const simulateResponse = (userMessage: string) => {
    setTimeout(() => {
      setMessages((prev) => [
        ...prev,
        {
          type: 'bot',
          text: `I understand that. Kindly navigate to <a href="https://wa.me/918306935111?text=${userMessage}" target="_blank" rel="noopener noreferrer" class="text-blue-500 underline">Chikki AI</a> to talk about it.`,
        },
      ]);
    }, 1000);
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!currentSymptom.trim()) return;

    setMessages((prev) => [...prev, { type: 'user', text: currentSymptom }]);
    setCurrentSymptom('');
    simulateResponse(currentSymptom);
  };

  return (
    <div className='h-fit min-h-64 min-w-full'>
      <motion.div
        className='w-full h-full bg-white dark:bg-neutral-900 rounded-xl shadow-lg flex flex-col'
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {/* Header */}
        <div className='p-4 border-b dark:border-neutral-800 flex items-center space-x-2'>
          <MessageCircle className='w-5 h-5 text-blue-500' />
          <span className='font-medium text-sm'>Chikki AI</span>
        </div>

        {/* Messages */}
        <div className='flex-1 p-4 space-y-4'>
          <AnimatePresence>
            {messages.map((message, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className={`flex ${message.type === 'user' ? 'justify-end' : 'justify-start'}`}
              >
                <div
                  className={`flex items-start gap-2 max-w-[100%] ${message.type === 'user' ? 'flex-row-reverse' : ''}`}
                >
                  <div className='w-8 h-8 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center flex-shrink-0'>
                    {message.type === 'user' ? (
                      <User className='w-4 h-4 text-blue-500' />
                    ) : (
                      <Bot className='w-4 h-4 text-blue-500' />
                    )}
                  </div>
                  <div
                    className={`py-2 px-3 rounded-lg ${
                      message.type === 'user'
                        ? 'bg-blue-500 text-white'
                        : 'bg-gray-100 dark:bg-neutral-800 text-gray-900 dark:text-gray-100'
                    }`}
                  >
                    <p
                      className='text-sm'
                      dangerouslySetInnerHTML={{ __html: message.text }}
                    />
                  </div>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>

        {/* Quick Symptoms */}
        <div className='p-3 border-t dark:border-neutral-800'>
          <div className='flex gap-2 overflow-x-auto pb-2'>
            {commonSymptoms.map((symptom) => (
              <motion.button
                key={symptom}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className='px-3 py-1 bg-blue-50 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400 rounded-full text-sm whitespace-nowrap'
                onClick={() => {
                  setCurrentSymptom(symptom);
                  setMessages((prev) => [
                    ...prev,
                    { type: 'user', text: symptom },
                  ]);
                  simulateResponse(symptom);
                }}
              >
                <div className='flex items-center space-x-1'>
                  <Plus className='w-3 h-3' />
                  <span>{symptom}</span>
                </div>
              </motion.button>
            ))}
          </div>
          <form onSubmit={handleSubmit} className='mt-2 flex gap-2'>
            <input
              type='text'
              value={currentSymptom}
              onChange={(e) => setCurrentSymptom(e.target.value)}
              placeholder='Type your symptoms...'
              className='flex-1 px-3 py-2 rounded-lg border dark:border-neutral-700 bg-transparent focus:outline-none focus:ring-2 focus:ring-blue-500'
            />
          </form>
        </div>
      </motion.div>
    </div>
  );
};
export const SkeletonThree = () => {
  return (
    <Link
      href='https://www.youtube.com/watch?v=hsQXv3uOX_w'
      target='_blank'
      className='relative flex gap-2 h-full group/image'
    >
      <div className='w-full mx-auto bg-transparent dark:bg-transparent h-full'>
        <div className='relative w-full aspect-[16/9]'>
          <Image
            src='/services/hospitals-youtube-thumbnail.jpg'
            alt='header'
            fill
            priority
            sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
            className='object-cover object-center rounded-2xl transition-all duration-200 group-hover/image:blur-md'
          />
          <div className='absolute inset-0 flex items-center justify-center z-10'>
            <IconBrandYoutubeFilled className='h-16 w-16 md:h-20 md:w-20 text-red-500' />
          </div>
        </div>
      </div>
    </Link>
  );
};

export const AnimatedRadiology = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [showAnalysis, setShowAnalysis] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveStep((prev) => (prev + 1) % 3);
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  const steps = [
    { icon: FlaskConical, color: 'from-purple-500 to-blue-500' },
    { icon: Monitor, color: 'from-blue-500 to-green-500' },
    { icon: FileText, color: 'from-green-500 to-emerald-500' },
  ];

  return (
    <div className='relative w-full h-full flex items-center justify-center'>
      <motion.div
        className='absolute inset-0 flex items-center justify-center'
        initial={false}
      >
        <motion.div
          className={`w-64 h-64 rounded-full bg-gradient-to-r ${steps[activeStep].color} opacity-10 blur-xl`}
          animate={{
            scale: [1, 1.2, 1],
          }}
          transition={{
            duration: 3,
            repeat: Infinity,
            ease: 'easeInOut',
          }}
        />
      </motion.div>

      <div className='relative w-56 h-56'>
        <motion.div
          className='absolute inset-0 border-4 border-dashed border-blue-200 dark:border-blue-900 rounded-full'
          animate={{ rotate: 360 }}
          transition={{
            duration: 20,
            repeat: Infinity,
            ease: 'linear',
          }}
        />

        <motion.div
          className='absolute inset-0 overflow-hidden rounded-full'
          initial={false}
        >
          <motion.div
            className='w-full h-1 bg-gradient-to-r from-transparent via-blue-500 to-transparent'
            animate={{
              y: [-100, 200],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: 'linear',
            }}
          />
        </motion.div>

        {steps.map((step, index) => (
          <motion.div
            key={index}
            className='absolute'
            style={{
              width: '100%',
              height: '100%',
              transformOrigin: 'center',
            }}
            animate={{
              rotate: [index * 120, index * 120 + 360],
            }}
            transition={{
              duration: 15,
              repeat: Infinity,
              ease: 'linear',
            }}
          >
            <div
              style={{
                position: 'absolute',
                left: '50%',
                top: '0',
                transform: 'translateX(-50%)',
              }}
            >
              <motion.div
                className={`p-3 rounded-xl bg-white dark:bg-neutral-800 shadow-lg
                    ${activeStep === index ? 'ring-2 ring-blue-500' : ''}`}
                whileHover={{ scale: 1.2 }}
                animate={{
                  scale: activeStep === index ? [1, 1.1, 1] : 1,
                }}
                transition={{
                  duration: 1,
                  repeat: activeStep === index ? Infinity : 0,
                }}
                onHoverStart={() => setShowAnalysis(true)}
                onHoverEnd={() => setShowAnalysis(false)}
              >
                {React.createElement(step.icon, {
                  className: `w-6 h-6 ${
                    activeStep === index ? 'text-blue-500' : 'text-gray-400'
                  }`,
                  size: 24,
                  strokeWidth: 1.5,
                })}
              </motion.div>
            </div>
          </motion.div>
        ))}

        <motion.div
          className='absolute inset-0 flex items-center justify-center'
          initial={false}
        >
          <motion.div
            className='p-6 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 shadow-lg'
            whileHover={{ scale: 1.1 }}
            animate={{
              scale: [1, 1.1, 1],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: 'easeInOut',
            }}
          >
            <Brain className='w-8 h-8 text-white' size={32} />
          </motion.div>
        </motion.div>
      </div>

      <AnimatePresence>
        {showAnalysis && (
          <motion.div
            className='absolute right-0 top-1/2 -translate-y-1/2 bg-white dark:bg-neutral-800 p-4 rounded-lg shadow-xl'
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
          >
            <div className='space-y-2'>
              <div className='flex items-center space-x-2'>
                <Activity className='w-4 h-4 text-green-500' size={16} />
                <span className='text-sm text-gray-600 dark:text-gray-300'>
                  Processing...
                </span>
              </div>
              <div className='flex items-center space-x-2'>
                <Wand className='w-4 h-4 text-blue-500' size={16} />
                <span className='text-sm text-gray-600 dark:text-gray-300'>
                  AI Analysis
                </span>
              </div>
              <div className='flex items-center space-x-2'>
                <CheckCircle className='w-4 h-4 text-emerald-500' size={16} />
                <span className='text-sm text-gray-600 dark:text-gray-300'>
                  Verified
                </span>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
