'use client';

import { Star } from 'lucide-react';
import * as React from 'react';
import { cn } from '@/components/lib/utils';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';

export interface TestimonialProps extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  role: string;
  company?: string;
  testimonial: string;
  rating?: number;
  image?: string;
}

const Testimonial = React.forwardRef<HTMLDivElement, TestimonialProps>(
  (
    {
      name,
      role,
      company,
      testimonial,
      rating = 5,
      image,
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={cn(
          'relative overflow-hidden rounded-2xl border border-primary/10 bg-background p-6 transition-all hover:shadow-lg dark:hover:shadow-primary/5 md:p-8',
          className,
        )}
        {...props}
      >
        <div className='absolute right-6 top-6 text-6xl font-serif text-muted-foreground/20'>
          &quot;
        </div>

        <div className='flex flex-col gap-4 justify-between h-full'>
          {rating > 0 && (
            <div className='flex gap-1'>
              {Array.from({ length: 5 }).map((_, index) => (
                <Star
                  key={index}
                  size={16}
                  className={cn(
                    index < rating
                      ? 'fill-yellow-400 text-yellow-400'
                      : 'fill-muted text-muted',
                  )}
                />
              ))}
            </div>
          )}

          <p className='text-pretty text-base text-muted-foreground'>
            {testimonial}
          </p>

          <div className='flex items-center gap-4 justify-start'>
            <div className='flex items-center gap-4'>
              {image && (
                <Avatar>
                  <AvatarImage
                    src={image}
                    alt={name}
                    height={48}
                    width={48}
                    className='object-cover'
                  />
                  <AvatarFallback>{name[0]}</AvatarFallback>
                </Avatar>
              )}

              <div className='flex flex-col'>
                <h3 className='font-semibold text-foreground'>{name}</h3>
                <p className='text-sm text-muted-foreground'>
                  {role}
                  {company && ` @ ${company}`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);
Testimonial.displayName = 'Testimonial';

export { Testimonial };
