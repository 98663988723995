'use client';

import { useRef, useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';

export const TermsAndConditions = () => {
  const [hasReadToBottom, setHasReadToBottom] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const content = contentRef.current;
    if (!content) return;

    const scrollPercentage =
      content.scrollTop / (content.scrollHeight - content.clientHeight);
    if (scrollPercentage >= 0.99 && !hasReadToBottom) {
      setHasReadToBottom(true);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant='link' className='transition-colors hover:text-primary'>
          Terms & Conditions
        </Button>
      </DialogTrigger>
      <DialogContent className='flex flex-col gap-0 p-0 sm:max-h-[min(640px,80vh)] sm:max-w-lg [&>button:last-child]:top-3.5'>
        <DialogHeader className='contents space-y-0 text-left'>
          <DialogTitle className='border-b border-border px-6 py-4 text-base'>
            Terms & Conditions
          </DialogTitle>
          <div
            ref={contentRef}
            onScroll={handleScroll}
            className='overflow-y-auto'
          >
            <DialogDescription asChild>
              <div className='lg:py-8 lg:px-16 p-4'>
                <h2 className='font-bold text-base text-justify'>
                  Important: Read Our Terms and Conditions Before Use
                </h2>
                <p className='font-normal text-base text-justify pt-4'>
                  The following terms and conditions (the{' '}
                  <span className='font-bold'> “Terms and Conditions”</span>)
                  govern your use of this Website provided to you by{' '}
                  <span className='font-bold'>
                    {' '}
                    Vigorus Healthtech Private Limited, having its registered
                    office at 562 A Kings Road, Nirman Nagar, Jaipur Rajasthan
                    302019
                  </span>{' '}
                  (hereinafter referred to as the “Company” or “us” or “we”,
                  which expression shall mean and include its officers,
                  successors and permitted assigns) and any content, features or
                  functionality made available from or through this Website,
                  including any sub-domains thereof, or application (the
                  “Website”). The Company has developed a blockchain-based data
                  management system for healthcare institutions/professionals.
                  The purpose of this system is to store complete medical
                  records of the patients provided by the healthcare
                  institutions/professionals, on this blockchain through the IoT
                  devices provided by the Company. This would allow the patients
                  to access the data digitally on the mobile application
                  developed by the Company and the same can be shared with any
                  registered doctor, licensed pharmacist, healthcare
                  institution/professional, etc. with a single click. The
                  healthcare institutions/professionals would be able to access
                  the medical data on both web and mobile applications which the
                  Company develops. The Services may be provided by the Company
                  through the Platform, and its suite of products and services,
                  as may be identified by the Company from time to time. These
                  Terms and Conditions set out the Company&apos;s practices with
                  respect to the entire scope of Services provided by the
                  Company.
                </p>
                <p className='font-normal text-base text-justify pt-4'>
                  {' '}
                  This document is an electronic record in terms of the
                  Information Technology Act, 2000 and rules there under as
                  applicable and the amended provisions pertaining to electronic
                  records in various statutes as amended by the Information
                  Technology Act, 2000. This electronic record is generated by a
                  computer system and does not require any physical or digital
                  signatures. This is a legal agreement between you, the user
                  (“User” or “You“) of the Service, as defined below, and
                  stating the terms that govern your use of the Website, as set
                  out below. If you do not agree to these Terms and Conditions,
                  you are not entitled to use the Website and any use thereafter
                  shall be unauthorized. Definition of “User” or “You” means any
                  person who accesses or avails of this site of the Company for
                  the purpose of hosting, publishing, sharing, transacting,
                  displaying, or uploading information or views and includes
                  other persons jointly participating in using the Website.
                </p>
                <p className='font-normal text-base text-justify pt-4'>
                  By continuing to access, link to, or use this Website, or any
                  service on this Website, you signify your acceptance of the
                  Terms and Conditions.
                </p>
                <p className='font-normal text-base text-justify pt-4'>
                  Company reserves the right to amend, remove, or add to the
                  Terms and Conditions at any time at its sole and absolute
                  discretion. Such modifications shall be effective immediately.
                  Accordingly, please continue to review the Terms and
                  Conditions whenever accessing, linking to, or using the
                  Website. Your access, link to, or use of the Website, or any
                  service on this Website, after the posting of modifications to
                  the Terms and Conditions, will constitute your acceptance of
                  the Terms and Conditions, as modified.
                </p>
                <p className='font-normal text-base text-justify pt-4'>
                  Some of the services on the Website may be subject to
                  additional posted Terms and conditions. Your use of those
                  services is subject to those conditions, which are
                  incorporated into these Terms and Conditions by reference. In
                  the event of an inconsistency between these Terms and
                  Conditions and any additional posted conditions, the
                  provisions of the additional conditions shall control.
                </p>
                <p className='font-normal text-base text-justify pt-4'>
                  {' '}
                  If, at any time, you do not wish to accept the Terms and
                  Conditions, you may not access, link to, or use the Website.
                  Any terms and conditions proposed by you which are in addition
                  to or conflict with the Terms and Conditions are expressly
                  rejected by the Company and shall be of no force or effect. In
                  such case, you are prohibited from accessing the Website and
                  any such access shall be unauthorized.
                </p>

                <ol className='font-normal text-base text-justify  '>
                  <li>
                    <h3 className='font-bold py-4'>1. Eligibility</h3>
                    <p className='font-normal text-base text-justify'>
                      You will be eligible for the Services on the Website only
                      if you are competent to contract under the Indian Contract
                      Act, 1872. A person is competent to contract under the
                      Indian Contract Act, 1872, inter-alia, if he/she is above
                      eighteen (18) years of age, is of a sound mind, and is not
                      disqualified from contracting by any law to which he/she
                      is subject. You hereby represent and warrant to the
                      Company that you are at least eighteen (18) years of age
                      or above and are capable of entering, performing, and
                      adhering to these Terms and Conditions and that you agree
                      to be bound by the following terms and conditions. While
                      individuals under the age of 18 may utilize the Services
                      of the Website, they shall do so only with the involvement
                      & guidance of their parents and/or legal guardians, under
                      such Parent /Legal guardian’s registered account. You
                      agree to register prior to uploading any content and/or
                      comment and any other use or Services of the Website and
                      provide your details including but not limited to complete
                      name, age, email address, residential address, and contact
                      number.
                    </p>
                  </li>
                  <li>
                    <h3 className='font-bold py-4'>2. Proprietary Rights</h3>
                    <p className='font-normal text-base text-justify'>
                      As between you and us, we own, solely and exclusively, all
                      rights, titles, and interest in and to the Website
                      including all the content (including, for example, audio,
                      photographs, illustrations, graphics, other visuals,
                      videos, copy, text, software, titles, Shockwave files,
                      etc.), code, data, and materials thereon, the look and
                      feel, design and organization of the Website, and the
                      compilation of the content, code, data, and materials on
                      the Website, including but not limited to any copyrights,
                      trademark rights, trade secrets, know-how, patent rights,
                      database rights, service marks, moral rights, sui generis
                      rights and other intellectual property and proprietary
                      rights therein (collectively referred to as the “Company
                      IPR“). Your use of the Company IPR does not grant you
                      ownership of any content, code, data, or materials you may
                      access on or through the Website or through the use of
                      Company IPR. Your use of the Company IPR is only a
                      limited, non-exclusive license to use the Company IPR
                      while accessing the Website. You agree to comply with all
                      written requests made by the Company or its suppliers and
                      licensors of content, equipment, or otherwise
                      (“Suppliers“) to protect their and others’ contractual,
                      statutory, and common law rights in the Company IPR.
                    </p>
                  </li>

                  <li>
                    <h3 className='font-bold py-4'>3. Acceptance Clause</h3>
                    <p className='font-normal text-base text-justify'>
                      The Terms and Conditions along with the privacy policy
                      shall apply to Users of Website. For creating access to
                      the Website/App, You have to register yourself with Us.
                      Any credentials given by You shall be kept confidential
                      except certain information which may be shared with
                      third-party with Your prior consent (in writing to the
                      prescribed email id). We shall communicate with You from
                      time to time via email or in any other manner
                      (offline/online) in regard to Your registration with us or
                      for any other updates/alerts. Your utilization of the
                      Website, Application, or administrations regarding the
                      Application, Website, or items (“Services”), or
                      enlistments with us through any modes or use of any items
                      will imply Your acknowledgment of this Policy and Your
                      consent to be lawfully limited by something very similar.
                    </p>
                  </li>

                  <li>
                    <h3 className='font-bold py-4'>4. Communication</h3>
                    <p className='font-normal text-base text-justify'>
                      When You use the Website or send emails or other data,
                      information or communication to us, You agree and
                      understand that You are communicating with Us through
                      electronic records and You consent to receive
                      communications via electronic records from Us periodically
                      and as and when required. We may communicate with you by
                      email or by any other mode of communication, electronic or
                      otherwise.
                    </p>
                  </li>
                  <li>
                    <h3 className='font-bold py-4'>5. Limited Use</h3>
                    <p className='font-normal text-base text-justify'>
                      All present and future rights in and title to the Company
                      IPR (including the right to exploit the Company IPR and
                      any portions thereof over any present or future
                      technology) are reserved for the Company for its exclusive
                      use. You may access and view the content on the Website on
                      your computer or other device and, except as specifically
                      permitted by these Terms and Conditions, you may not copy
                      or make any use of the Company IPR or any portion thereof.
                      Unless otherwise specifically indicated in these Terms and
                      Conditions or on the Website, use of the Website and the
                      Company IPR, are only for your personal, non-commercial,
                      and internal use. Except as specifically permitted herein,
                      you shall not use the Website or the Company IPR, or any
                      portion thereof or the names of any individual participant
                      in, or contributor to, the Company IPR, or any variations
                      or derivatives thereof, for any purpose, without the
                      Company’s prior written approval.
                    </p>
                  </li>

                  <li>
                    <h3 className='font-bold py-4'>6. Prohibited Use</h3>
                    <p className='font-normal text-base text-justify'>
                      Unless otherwise specifically indicated in these Terms and
                      Conditions or on the Website, any commercial or
                      promotional distribution, publishing, re-circulation,
                      re-distribution or exploitation of the Service or any
                      content, code, data, or materials on the Website, or any
                      portion of the Service or Company IPR is strictly
                      prohibited unless you have received the express prior
                      written permission from our authorized personnel or the
                      otherwise applicable rights holder. Other than as
                      expressly allowed herein or on the Website, you may not
                      download, post, display, store, publish, copy, reproduce,
                      distribute, transmit, modify, perform, broadcast,
                      transfer, create derivative works from, sell or otherwise
                      exploit any content, code, data or materials of, on or
                      available through the Website or of the Service. You
                      further agree that you may not alter, edit, delete,
                      remove, otherwise change the meaning or appearance of, or
                      repurpose, recompile, decompile reverse engineer any of
                      the content, code, data, or other materials on or
                      available through the Website, including, without
                      limitation, the alteration or removal of any trademarks,
                      trade names, logos, service marks, or any other
                      proprietary content or proprietary rights notices.
                    </p>
                    <br />
                    <p className='font-normal text-base text-justify'>
                      You shall not use the Service or the Website for any
                      illegal purpose, for the facilitation of the violation of
                      any law or regulation, or in any manner inconsistent with
                      the Terms and Conditions. You agree not to use, transfer,
                      distribute, or dispose of any information contained in the
                      Service or Company IPR in any manner that could compete
                      with the business of the Company or any of its Suppliers.
                      You may not input, distribute, upload, post, email,
                      transmit or otherwise make available any content through
                      the Website that: (i) is promotional in nature, including
                      solicitations for funds or business, without the prior
                      written authorization of the Company, or constitutes junk
                      mail, spam, chain letters, pyramid schemes or the like;
                      (ii) is unlawful, harmful, threatening, abusive,
                      harassing, tortious, defamatory, vulgar, obscene,
                      libelous, invasive of another’s privacy, hateful, or
                      racially, ethnically or otherwise objectionable; (iii) for
                      which you do not have the right to make available under
                      any law or under contractual or fiduciary relationships
                      (such as inside information, proprietary and confidential
                      information learned or disclosed as part of employment
                      relationships or under nondisclosure agreements); (iv)
                      infringes any patent, trademark, trade secret, copyright
                      or other proprietary rights of any party; (v) contains
                      software viruses or any other computer code, files or
                      programs designed to interrupt, destroy or limit the
                      functionality of any computer software or hardware, or
                      telecommunications equipment; (vi) is harmful to minors;
                      (vii) constitutes the personally identifiable information
                      of any other person that such person has not authorized
                      you to disclose; (viii) Is grossly harmful, harassing,
                      blasphemous, paedophilic, invasive of another’s privacy,
                      hateful, or racially, ethnically objectionable,
                      disparaging, relating or encouraging money laundering or
                      gambling, or otherwise unlawful in any manner whatever;
                      (ix) Harm minors in any way; (x) Infringes any patent,
                      trademark, copyright or other proprietary rights; (xi)
                      Impersonates another person; (xii) Contains software
                      viruses or any other computer code, files or programs
                      designed to interrupt, destroy or limit the functionality
                      of any computer resource; (xiii) Threatens the unity,
                      integrity, defence, security or sovereignty of India,
                      friendly relations with foreign states, or public order or
                      causes incitement to the commission of any cognizable
                      offence or prevents investigation of any offence or is
                      insulting any other nation; (xiv) Results in advertising
                      or solicitation to anyone to buy or sell products or
                      services, or to make donations of any kind; (xv) Discloses
                      any personally identifying information or private
                      information about any third parties without their express
                      consent; (xvi) Results in spamming, spidering, “screen
                      scraping,” “database scraping,”, phishing or harvesting
                      email addresses that have been posted by other users of
                      the Sites; (xvii) Uses or attempt to use another person’s
                      account, password or services; (xviii) Disrupts,
                      interferes with or otherwise violates the security of the
                      Sites or any services, accounts, passwords, servers or
                      networks related to the Site; (xix) Sends repeated
                      messages to another user or repeat postings of the same
                      message under the same or multiple subjects or engage in
                      cyber stalking or become a nuisance for other users; (xx)
                      Damages, disables, overburdens, or impairs the Company’s
                      servers, or the network(s) connected to the Company’s
                      servers, or interferes with any other party’s use and
                      enjoyment of any Services; (xxi) Obtains or attempts to
                      obtain any materials or information through any means not
                      intentionally made available through the Media/Services.
                    </p>
                    <br />
                    <p className='font-normal text-base text-justify'>
                      You may not use any of the Services / Company IPR in any
                      manner which creates the impression that such items belong
                      to or are associated with you or are used with the
                      Company&apos;s consent, and you acknowledge that you have
                      no ownership rights in and to any of such items.You agree
                      not to use any framing techniques to enclose any Service
                      or Company IPR; or remove, conceal or obliterate any
                      copyright or other proprietary notice or any credit-line
                      or date-line on other mark or source identifier included
                      on the Website / Service, including without limitation,
                      the size, color, location or style of all proprietary
                      marks.
                    </p>
                    <br />
                    <p className='font-normal text-base text-justify'>
                      You may neither interfere with nor attempt to interfere
                      with nor otherwise disrupt the proper working of the
                      Website, any activities conducted on or through the
                      Website or any servers or networks connected to the
                      Website.
                    </p>
                    <br />
                    <p className='font-normal text-base text-justify'>
                      You may neither obtain nor attempt to obtain through any
                      means any Company IPR that have not been intentionally
                      made publicly available either by public display on the
                      Website or through accessibility by a visible link on the
                      Website. You shall not violate the security of the Website
                      or attempt to gain unauthorized access to the Website,
                      data, materials, information, computer systems or networks
                      connected to any server associated with the Website,
                      through hacking, password timing or any other means.
                    </p>
                    <br />
                    <p className='font-normal text-base text-justify'>
                      You may neither take nor attempt any action that, in the
                      sole discretion of the Company, imposes or may impose an
                      unreasonable or disproportionately large load or burden on
                      the Company or the infrastructure of the Website. You
                      shall not use or attempt to use any “scraper,” “robot,”
                      “bot,” “spider,” “data mining,” “computer code,” or any
                      other automate device, program, tool, algorithm, process
                      or methodology to access, acquire, copy, or monitor any
                      portion of the Website, any data or content found on or
                      accessed through the Website, or any other Website
                      information without the prior express written consent of
                      the Company. You may not forge headers or otherwise
                      manipulate identifiers in order to disguise the origin of
                      any other content.
                    </p>
                  </li>

                  <li>
                    <h3 className='font-bold py-4'>7. Charges</h3>
                    <p className='font-normal text-base text-justify'>
                      Membership on the Website is free for buyers. The Company
                      does not charge any fee for browsing and buying on the
                      Website. The Fee Policy may be changed from time to time
                      by the Company. The Website may be updated and new
                      services may be introduced or modified at the sole
                      discretion of the Company. In such an event, the Company
                      reserves the right to introduce fees for the new services
                      offered or amend/introduce fees for existing services, as
                      the case may be. Changes to the Fee Policy shall be posted
                      on the Website and such changes shall automatically become
                      effective immediately after they are posted on the
                      Website. Unless otherwise stated, all fees shall be quoted
                      in Indian Rupees. Whenever you make payments to the
                      Company, you are solely responsible for complying with all
                      applicable laws, including those in India.
                    </p>
                  </li>

                  <li>
                    <h3 className='font-bold py-4'>8. Trademarks</h3>
                    <p className='font-normal text-base text-justify'>
                      The trademarks, logos, service marks and trade names
                      (collectively the “Trademarks“) displayed on the Website
                      or on content available through the Website are our
                      registered and unregistered Trademarks and others and may
                      not be used in connection with products and/or services
                      that are not related to, associated with, or sponsored by
                      their rights holders that are likely to cause customer
                      confusion, or in any manner that disparages or discredits
                      their rights holders. All Trademarks not owned by us that
                      appear on the Website or on or through the Website’s
                      services, if any, are the property of their respective
                      owners. Nothing contained on the Website should be
                      construed as granting, by implication, estoppel, or
                      otherwise, any license or right to use any Trademark
                      displayed on the Website without our written permission or
                      the third party that may own the applicable Trademark.
                      Your misuse of the Trademarks displayed on the Website or
                      on or through any of the Website’s services is strictly
                      prohibited.
                    </p>
                  </li>

                  <li>
                    <h3 className='font-bold py-4'>
                      9. Indemnification Clause
                    </h3>
                    <p className='font-normal text-base text-justify'>
                      You agree to defend, hold harmless, and indemnify the
                      Company and its Participants, and their respective
                      subsidiaries, affiliates, directors, officers, agents, and
                      employees from and against any third-party claims,
                      actions, or demands arising out of, resulting from or in
                      any way related to Your use of the Website, including any
                      liability or expense arising from any and all claims,
                      losses, damages (actual and consequential), suits,
                      judgments, litigation costs, and attorney&apos;s fees, of
                      every kind and nature. In such a case, the Company or its
                      Participant will provide You with written notice of such
                      claim, suit, or action.
                    </p>
                  </li>

                  <li>
                    <h3 className='font-bold py-4'>
                      10. Termination/ Discontinuance of Rights
                    </h3>
                    <p className='font-normal text-base text-justify'>
                      The Company in its sole discretion, may terminate Your use
                      or User’s account of the Website/Services or Your
                      participation in it, for any reason or no reason, upon
                      notice to You. Those who violate the terms of services are
                      subjected to termination of availed services. The Company
                      reserves the right at any time in its sole discretion to
                      cancel, delay, reschedule update/modify to alter the
                      format of any course offered through it, or to cease
                      providing any part or all of the Site content or related
                      services. You agree that neither the Company nor any of
                      its Participants will have any liability to You for such
                      an action.
                    </p>
                    <br />
                    <p className='font-normal text-base text-justify'>
                      If You no longer desire to participate in the
                      Site/Services, You may terminate Your participation at any
                      time. The rights granted to You hereunder will terminate
                      upon any termination of Your right to use.
                    </p>
                  </li>

                  <li>
                    <h3 className='font-bold py-4'>11. Monitoring</h3>
                    <p className='font-normal text-base text-justify'>
                      To the extent permitted by law, we may record and monitor
                      Your communications with us to ensure compliance with our
                      legal and regulatory obligations and our internal
                      policies. This may include the recording of telephone
                      conversations.
                    </p>
                  </li>

                  <li>
                    <h3 className='font-bold py-4'>
                      12. Confidentiality of Information
                    </h3>
                    <p className='font-normal text-base text-justify'>
                      You will keep in confidence and protect from disclosure
                      any confidential and proprietary information concerning
                      the Company that you may be exposed to during your
                      engagement with the Company. You will not use any such
                      information for any purpose that is detrimental to the
                      Company.
                    </p>
                    <br />
                    <p>
                      The Company is not and will not be responsible for and do
                      not necessarily hold the opinions expressed by its
                      bloggers / content contributors, etc. Opinions and other
                      statements expressed by contributors / bloggers / users
                      are theirs alone, not opinions of the Company. You are
                      solely responsible for your content by way of accuracy and
                      completeness, and the Company does not necessarily endorse
                      it. You acknowledge that by providing you with the ability
                      to view and distribute Content on our Website, the Company
                      is not undertaking any obligation or liability relating to
                      the Content. The Company and its affiliates, successors,
                      assigns and their respective employees, agents, directors,
                      officers and shareholders do not undertake or assume any
                      duty to monitor our Website for inappropriate or unlawful
                      Content.
                    </p>
                    <br />
                    <p>
                      The Company and its affiliates, successors, assigns and
                      their respective, employees, agents, directors, officers
                      and shareholders assume no responsibility or liability
                      which may arise from the Content thereof, including, but
                      not limited to, claims for defamation, libel, slander,
                      infringement, invasion of privacy and publicity rights,
                      obscenity, pornography, profanity, fraud, or
                      misrepresentation or claims that Content violates
                      applicable laws and/or are covered under Prohibited
                      Activities specifically mentioned in these Terms and
                      Conditions. The Company reserves the right to block or
                      remove communications, postings or materials at any time
                      in its sole discretion. The Company accepts no liability
                      regarding any unauthorised use of blogs.
                    </p>
                  </li>

                  <li>
                    <h3 className='font-bold py-4'>13.Jurisdiction of Court</h3>
                    <p className='font-normal text-base text-justify'>
                      Unless otherwise specified, the material on the Website is
                      presented solely for the purpose of sale in India. The
                      Company makes no representation that materials on the
                      Website are appropriate or available for use in other
                      locations/Countries other than India. If a user accesses
                      this site from a location/country other than India, they
                      are voluntarily doing so, and the Company is not
                      responsible for supplying products to such
                      locations/countries, or for reimbursing them, or for
                      complying with local laws, if and to the extent
                      applicable. The Courts of law at Jaipur (Rajasthan) shall
                      have exclusive jurisdiction over any disputes arising
                      under the Terms and Conditions.
                    </p>
                  </li>

                  <li>
                    <h3 className='font-bold py-4'>
                      14. Disclaimer of Warranties and Liabilities
                    </h3>
                    <p className='font-normal text-base text-justify'>
                      This Website, all the materials and products (including
                      but not limited to software), and services, included on or
                      otherwise made available to You through this site are
                      provided on &quot;as is&quot; and &quot;as available&quot;
                      basis without any representation or warranties, express or
                      implied except otherwise specified in writing. Without
                      prejudice to the foregoing paragraph, the Company does not
                      warrant that:
                    </p>{' '}
                    <br />
                    <p className='font-normal text-base text-justify'>
                      • This Website will be constantly available, or available
                      at all; or
                    </p>
                    <br />
                    <p className='font-normal text-base text-justify'>
                      • The information on this Website is complete, true,
                      accurate or non-misleading.
                    </p>
                    <br />
                    <p className='font-normal text-base text-justify'>
                      The Company will not be liable to You in any way or in
                      relation to the Contents of, or use of, or otherwise in
                      connection with, the Website. The Company does not warrant
                      that this site; information, Content, materials, product
                      (including software) or services included on or otherwise
                      made available to You through the Website; their servers;
                      or electronic communication sent from Us are free of
                      viruses or other harmful components. Nothing on the
                      Website constitutes or is meant to constitute, advice of
                      any kind.
                    </p>
                  </li>

                  <li>
                    <h3 className='font-bold py-4'>
                      15. Limitation of Liability
                    </h3>
                    <p className='font-normal text-base text-justify'>
                      IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY SPECIAL,
                      INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES OF ANY KIND
                      IN CONNECTION WITH THESE TERMS AND CONDITIONS, EVEN IF THE
                      USER HAS BEEN INFORMED IN ADVANCE OF THE POSSIBILITY OF
                      SUCH DAMAGES.
                    </p>
                  </li>

                  <li>
                    <h3 className='font-bold py-4'>16. General</h3>
                    <p className='font-normal text-base text-justify'>
                      You may not assign these Terms of Use or any of your
                      interests, rights, or obligations under these Terms of
                      Use. If any provision of these Terms of Use shall be found
                      to be invalid by any court having competent jurisdiction,
                      the invalidity of such provision shall not affect the
                      validity of the remaining provisions of these Terms of
                      Use, which shall remain in full force and effect. No
                      waiver of any of these Terms of Use shall be deemed a
                      further or continuing waiver of such term or condition or
                      any other term or condition. You may preserve these Terms
                      of Use in written form by printing them for Your records,
                      and You waive any other requirement for these Terms of Use
                      to be proved by means of a written document.
                    </p>
                  </li>

                  <li>
                    <h3 className='font-bold py-4'>17. Contact Information</h3>
                    <p className='font-normal text-base text-justify'>
                      Please contact us for any questions or comments (including
                      all inquiries unrelated to copyright infringement)
                      regarding this Website at
                      <a
                        href='mailto:connect@vigorushealthtech.com'
                        className='underline'
                      >
                        {' '}
                        connect@vigorushealthtech.com
                      </a>
                    </p>{' '}
                    <br />
                    <p className='font-normal text-base text-justify'>
                      Grievance officer
                    </p>{' '}
                    <br />
                    <p className='font-normal text-base text-justify'>
                      In accordance with Information Technology Act 2000 and
                      rules made there under, the name and contact details of
                      the Grievance Officer are provided below:
                    </p>{' '}
                    <br />
                    <p className='font-normal text-base text-justify'>
                      {' '}
                      Name: Mr. Chirag Goyal{' '}
                    </p>{' '}
                    <br />
                    <p className='font-normal text-base text-justify'>
                      {' '}
                      Designation: CTO
                    </p>{' '}
                    <br />
                    <p className='font-normal text-base text-justify '>
                      Email:{' '}
                      <a
                        className='underline '
                        href='mailto:chirag@vigorushealth.com'
                      >
                        chirag@vigorushealth.com
                      </a>
                    </p>
                  </li>
                  <li>
                    <h3 className='font-bold py-4'> 18. Headings</h3>
                    <p className='font-normal text-base text-justify'>
                      The section titles in these Terms and Conditions are used
                      solely for the convenience of you and the Company and have
                      no legal or contractual significance.
                    </p>
                  </li>
                  <li>
                    <h3 className='font-bold py-4'>19. Severability</h3>
                    <p className='font-normal text-base text-justify'>
                      If any provision of these Terms and Conditions is found
                      invalid or unenforceable, that provision will be enforced
                      to the maximum extent permissible, and the other
                      provisions of these Terms and Conditions will remain in
                      force.
                    </p>
                  </li>
                  <li>
                    <h3 className='font-bold py-4'>20. Entire Agreement</h3>
                    <p className='font-normal text-base text-justify'>
                      These Terms and Conditions and the Privacy Policy and any
                      other terms and conditions of service on the Website, and
                      its successor, constitute the entire agreement between you
                      and the Company and govern your use of the Service and the
                      Website.
                    </p>
                  </li>
                  <li>
                    <h3 className='font-bold py-4'>21. Miscellaneous</h3>
                    <p className='font-normal text-base text-justify'>
                      You accept that the Company has the right to change the
                      content or technical specifications of any aspect of the
                      Service and/or the Website at any time in the Company’s
                      sole discretion. You further accept that such changes may
                      result in your being unable to access the Service and/or
                      the Website. The failure of the Company to exercise or
                      enforce any right or provision of the Terms and Conditions
                      shall not constitute a waiver of such right or provision.
                      The Company may assign its rights under these Terms and
                      Conditions without notice. These Terms and Conditions will
                      be binding upon and will inure to the benefit of the
                      parties, their successors and permitted assigns.
                    </p>
                  </li>
                </ol>
              </div>
            </DialogDescription>
          </div>
        </DialogHeader>
        <DialogFooter className='border-t border-border px-6 py-4 sm:items-center'>
          {!hasReadToBottom && (
            <span className='grow text-xs text-muted-foreground max-sm:text-center'>
              Read all terms before accepting.
            </span>
          )}
          <DialogClose asChild>
            <Button type='button' variant='outline'>
              Cancel
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button type='button' disabled={!hasReadToBottom}>
              I agree
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
