'use client';

import { useRef, useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';

export const PrivacyPolicy = () => {
  const [hasReadToBottom, setHasReadToBottom] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const content = contentRef.current;
    if (!content) return;

    const scrollPercentage =
      content.scrollTop / (content.scrollHeight - content.clientHeight);
    if (scrollPercentage >= 0.99 && !hasReadToBottom) {
      setHasReadToBottom(true);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant='link' className='transition-colors hover:text-primary'>
          Privacy Policy
        </Button>
      </DialogTrigger>
      <DialogContent className='flex flex-col gap-0 p-0 sm:max-h-[min(640px,80vh)] sm:max-w-lg [&>button:last-child]:top-3.5'>
        <DialogHeader className='contents space-y-0 text-left'>
          <DialogTitle className='border-b border-border px-6 py-4 text-base'>
            Privacy Policy
          </DialogTitle>
          <div
            ref={contentRef}
            onScroll={handleScroll}
            className='overflow-y-auto'
          >
            <DialogDescription asChild>
              <div className='py-8 md:px-16 p-4'>
                <h2 className='font-bold text-base text-justify'>
                  With effect from 1st January, 2024
                </h2>
                <p className='font-normal text-base text-justify pt-4'>
                  Welcome to Chikitsa (the “Platform”). This Platform and its
                  suite of products and services are owned and operated by
                  Vigorus Healthtech Private Limited, having its registered
                  office at 562 A Block Ab Kings Road, Nirman Nagar, Jaipur
                  Rajasthan 302019 (hereinafter referred to as the “Company” or
                  “us” or “we”, which expression shall mean and include its
                  officers, successors and permitted assigns). The Company has
                  developed a blockchain-based data management system for
                  healthcare institutions/professionals. The purpose of this
                  system is to store complete medical records of the patients
                  provided by the healthcare institutions/professionals, on this
                  blockchain through the IoT devices provided by the Company.
                  This would allow the patients to access the data digitally on
                  the mobile application developed by the Company and the same
                  can be shared with any registered doctor, licensed pharmacist,
                  healthcare institution/professional etc. with a single click.
                  The healthcare institutions/professionals would be able to
                  access the medical data on both web and mobile applications
                  which the Company develops. The Services may be provided by
                  the Company through the Platform, and its suite of products
                  and services, as may be identified by the Company from time to
                  time. This Privacy Policy (“Privacy Policy”) sets out the
                  Company&apos;s privacy practices with respect to the entire
                  scope of Services provided by the Company.
                </p>

                <p className='font-normal text-base text-justify pt-4'>
                  This document is published in accordance with the provisions
                  of the Information Technology Act, 2000 and the rules made
                  thereunder that require publishing the rules and regulations,
                  privacy policy, and terms of use on an online portal of the
                  Company. We request you to go through this Privacy Policy and
                  the Terms of Use carefully before you decide to access this
                  Platform.
                </p>
                <p className='font-normal text-base text-justify pt-4'>
                  For the purposes of this Privacy Policy, the words “us”, “we”,
                  and “our” refer to the Company and all references to “you”,
                  “your” or “user”, as applicable mean the person who accesses,
                  uses and/or participates in the Platform in any manner or
                  capacity.
                </p>
                <p className='font-normal text-base text-justify pt-4'>
                  The Company is strongly committed to protecting the privacy of
                  its users and has taken all necessary and reasonable measures
                  to protect the confidentiality of the user information and its
                  transmission through the internet.
                </p>
                <h3 className='font-bold py-4'> General Terms:</h3>
                <ol className='list-decimal font-normal text-base text-justify px-6'>
                  <li>
                    The Company shall not be held liable for disclosure of any
                    information if such disclosure is in accordance with this
                    Privacy Policy, the Terms of Use, and/or applicable law.
                  </li>
                  <li>
                    By accessing or using the Platform, or by otherwise giving
                    us your information, you confirm that you have the capacity
                    to enter into a legally binding contract under the laws of
                    India, and have read, understood, and agreed to the
                    practices and policies outlined in this Privacy Policy and
                    agree to be bound by the Privacy Policy.
                  </li>
                  <li>
                    You hereby consent to our collection, use, sharing, and
                    disclosure of your information as described in this Privacy
                    Policy. We reserve the right to change, modify, add, or
                    delete portions of the terms of this Privacy Policy, at our
                    sole discretion, at any time, and any continued use of the
                    Platform, following any such amendments to the Privacy
                    Policy, will be deemed as an implicit acceptance of the
                    Privacy Policy in its amended form. You are requested to
                    review the Privacy Policy from time to time to keep yourself
                    updated with any changes, or modifications made to the terms
                    hereof.
                  </li>
                  <li>
                    If you are accessing or using this Platform from an overseas
                    location, you do so at your own risk and shall be solely
                    liable for compliance with any applicable local laws.
                  </li>
                  <li>
                    If you do not agree with any of the terms and conditions of
                    this Privacy Policy, please do not proceed further to use
                    this Platform. This Privacy Policy is subject to change at
                    any time without notice. To make sure you are aware of any
                    changes, please review this policy on this Platform
                    periodically.
                  </li>
                  <li>
                    If you object to your information being transferred or used
                    in accordance with this Privacy Policy, please do not use
                    the Platform.
                  </li>
                  <p>
                    All capitalized terms used but not defined herein shall have
                    the meaning ascribed to in the Terms of Use.
                  </p>
                </ol>

                <h3 className='font-bold py-4'>1. Information Collection</h3>

                <ul className='list-disc font-normal text-base text-justify px-6'>
                  <li>
                    You (i.e. the User) are the sole owner of your (i.e. User’s)
                    information. The Company just collects your information
                    through the Platform for providing the products and services
                    you have signed up for. We will not sell, share, transfer,
                    or rent any personal information to others in ways different
                    from what is disclosed in this privacy policy and the Terms
                    of Use.
                  </li>
                  <li>
                    The Company collects information from you on the
                    Register/Log-in page of the Platform. On the signup page,
                    you are required to give your personally identifiable
                    contact information (such as name, mobile number, email ID,
                    etc.). A verification process is used to confirm your
                    identity through the contact information that you provide.
                    When the Platform requests your identity, the Platform will
                    clearly indicate the purpose of the inquiry before the
                    information is requested.
                  </li>
                  <li>
                    Once a registered participant, you have the option of
                    providing additional personally identifiable, health and
                    other information including but not limited to age, height,
                    weight, occupation, name of your doctor, hospital or other
                    healthcare provider, location, data relating to your diet,
                    activity levels, exercise, medical reports (if any), medical
                    history, mobile number, email ID, name of a primary
                    caregiver, contact details of the primary caregiver, payment
                    information and other personal information to avail of the
                    Services provided through the Platform. Providing additional
                    information beyond what is required at registration is
                    entirely optional and can be altered or removed by you at
                    any time. We assume that any information provided by you
                    relating to a primary caregiver has been provided after
                    obtaining the due consent of such primary caregiver.
                  </li>
                  <li>
                    Every computer/mobile device connected to the Internet is
                    given a domain name and a set of numbers that serve as that
                    computer’s Internet Protocol or “IP” address. When you
                    request a page from any page within the Company platform,
                    our web servers automatically recognize your domain name and
                    IP address. The domain name and IP address reveal nothing
                    personal about you other than the IP address from which you
                    have accessed the Platform .
                  </li>
                </ul>

                <h3 className='font-bold py-4'>
                  2.Children’s And Minor’s Privacy
                </h3>
                <p className='font-normal text-base text-justify pt-4'>
                  The Company strongly encourages parents and guardians to
                  supervise the online activities of their minor children and
                  consider using parental control tools available from online
                  services and software manufacturers to help provide a
                  child-friendly online environment. These tools also can
                  prevent minors from disclosing their name, address, and other
                  personally identifiable information online without parental
                  permission. Although the Platform is not intended for use by
                  minors, the Company respects the privacy of minors who may
                  inadvertently use the Internet or the Platform.
                </p>

                <h3 className='font-bold py-4'>3.Data Retention</h3>
                <p className='font-normal text-base text-justify pt-4'>
                  Subject to the other terms contained in this Privacy Policy
                  and the Terms of Use, your information will be retained with
                  the Company until you specifically request the Company to
                  destroy such information by way of a written request. The
                  Company shall, upon receipt of such request from you, proceed
                  to delete such information after carrying on internal
                  verification procedures. Notwithstanding the foregoing, the
                  Company will retain and use your information as necessary to
                  comply with its legal obligations, resolve disputes, carry on
                  audits, and enforce its agreements or for other business
                  purposes. The data is stored on cloud servers (AWS) in India.
                </p>
                <h3 className='font-bold py-4'>4.Opt-Out Procedures</h3>
                <p className='font-normal text-base text-justify pt-4'>
                  Upon initial communication from the Company, you may opt out
                  of receiving further communications from the Company. To be
                  completely removed from the Company subscription list, you may
                  contact us at support@vigorushealthtech.com. If you are using
                  an e-mail forwarding service or other similar service please
                  make sure to include the correct e-mail address or addresses.
                </p>
                <h3 className='font-bold py-4'>5.Company as a Pass-Through</h3>

                <p className='font-normal text-base text-justify pt-4'>
                  The Company may be a pass-through facilitator for data under a
                  contract with a client or partner (“Enterprise Client”). In
                  such cases, the Company shall not be deemed to be the owner of
                  such information. The Company may, as part of the agreement
                  with such Enterprise Clients, use the information collected
                  from such Enterprise Clients to provide the relevant services
                  to the user. The Company may continue to use the data for the
                  purposes identified in this statement and the Terms of Use.
                </p>
                <p className='font-normal text-base text-justify pt-4'>
                  Please note however, that in respect of any information
                  received by the Company under certain specific patient support
                  program: (a) the Company shall retain identified data for a
                  period of 5 (Five) years from the date of termination of such
                  agreement, for performance of its contractual obligations,
                  audits, diligence thereunder; and (b) the Company shall
                  thereafter be permitted to retain only de-identified data for
                  such period of time as may be necessary. The de-identified
                  data at any point of time will be used for the purpose of
                  compliance with applicable law, carrying on its contractual
                  obligations, for carrying on audits and improvement of its
                  technology, and strengthening its AI engine. All personal
                  identification data will be deleted after a period of 5 years
                  from the date of termination of the agreement.
                </p>

                <h3 className='font-bold py-4'>
                  {' '}
                  6. Use of the Information Collected
                </h3>
                <h4 className='font-normal text-base text-justify pt-4'>
                  6.1. Use of Information for Services
                </h4>
                <p className='font-normal text-base text-justify pt-4'>
                  The primary goal of the Company in collecting the information
                  is to provide you the Services as defined in the Terms of Use.
                  The Company may use the personal and non-personal information
                  provided by you, including but not limited to, the following
                  ways:
                </p>
                <ol className='list-decimal font-normal text-base text-justify px-6'>
                  <li>Identify and reach you;</li>
                  <li>
                    Resolve technical issues of access to the Platform via
                    telephone or email;
                  </li>
                  <li>
                    Provide you with further information, products and services;
                  </li>
                  <li>Better understand of users’ needs and interests;</li>
                  <li>Personalize your experience and customize experience;</li>
                  <li>
                    {' '}
                    Diagnosing and assisting you in your clinical, health, and
                    general well-being progress;
                  </li>
                  <li>
                    Run statistical research and undertake scientific
                    publications;
                  </li>
                  <li>
                    Improving the product and services offered to current and
                    future users and partners by improving parts of the
                    Platform, including but not limited to the algorithms, logic
                    systems, content, decision support, engineering, and
                    artificial intelligence;
                  </li>
                  <li>
                    Providing access to your information to healthcare
                    practitioners and partners;
                  </li>
                  <li>
                    Providing data to clients or partners under the terms of
                    agreement with them;
                  </li>
                  <li>
                    Providing access to your “User Profile” to other users of
                    the Platform, Company administrators, Company moderators,
                    Health Coaches and Primary Caregivers;
                  </li>
                  <li>
                    For carrying on audits, due diligence for effecting in
                    vestments in the company
                  </li>
                  <li>
                    Detect and protect us against error, fraud, and other
                    criminal activity;
                  </li>
                  <li>
                    {' '}
                    Make disclosures as may be required under applicable law.{' '}
                  </li>
                  <li>
                    Improve our Platform in order to better serve the user;
                  </li>
                  <li>
                    Allow us to better service the user in responding to
                    customer service requests;
                  </li>
                  <li>
                    Administer a contest, promotion, survey or other site
                    feature;and
                  </li>
                  <li>
                    {' '}
                    Provide access to other users as part of a social feed, in
                    order to provide motivation or assistance to such other
                    users
                  </li>
                </ol>
                <h4 className='font-normal text-base text-justify pt-4'>
                  6.2. Sale of Assets, Merger, Acquisition
                </h4>
                <p className='font-normal text-base text-justify pt-4'>
                  The Company may share your information, including your
                  information with our parent, subsidiaries and affiliates for
                  internal purposes. The Company also reserves the right to
                  disclose and transfer all such information: (i) to a
                  subsequent owner, co-owner or operator of the Platform,
                  Website or applicable database; or (ii) in connection with a
                  corporate merger, consolidation, restructuring, the sale of
                  substantially all of our membership interests and/or assets or
                  other corporate change, including, during the course of any
                  due diligence process. You will be notified via email and/or a
                  prominent notice on our Platform and/ or Website of any change
                  in ownership or uses of your personal information, as well as
                  any choices you may have regarding your personal information.
                </p>
                <h4 className='font-normal text-base text-justify pt-4'>
                  6.3. Cookies
                </h4>
                <p className='font-normal text-base text-justify pt-4'>
                  Cookies are small pieces of information saved by your browser
                  onto your computer/mobile. Cookies are used to record various
                  aspects of your visit and assist the Company in providing you
                  with uninterrupted Service. The Company does not use cookies
                  to save personal information for unauthorized use.
                </p>
                <h4 className='font-normal text-base text-justify pt-4'>
                  6.4 Ownership of work Products
                </h4>
                <p className='font-normal text-base text-justify pt-4'>
                  Any information provided by you through the Platform may be
                  utilized by the Company for carrying on data analytics,
                  derivatives, and other work products. The Company shall be
                  deemed to be the sole owner of any and all such data
                  analytics, derivatives, and work products, and may use all
                  such data analytics, derivatives and work products for each of
                  the purposes identified in this Privacy Policy and the Terms
                  of Use.
                </p>
                <h4 className='font-normal text-base text-justify pt-4'>
                  6.5. Non-disclosure of Information
                </h4>
                <p className='font-normal text-base text-justify pt-4'>
                  Subject to the provisions of this Privacy Policy and the Terms
                  of Use, the Company pledges that it will not sell or rent your
                  personal details to anyone and your personal information will
                  be protected and maintained strictly confidential by the
                  Company except in the following cases:
                </p>
                <ol className='list-decimal font-normal text-base text-justify px-6'>
                  <li>
                    {' '}
                    The Company may disclose your personal information in the
                    event it is required to do so by law, rule, regulation, law,
                    enforcement, governmental official, legal or regulatory
                    authorities and, or, to such other statutory bodies who have
                    appropriate authorization to access the same for any
                    specific legal purposes;
                  </li>
                  <li>
                    The Company may disclose your information in order to
                    provide you the Services, enforce or apply the Terms of Use,
                    or to protect the rights, property or safety of the Company,
                    its users or others. This includes exchanging information
                    with other companies/agencies that work for fraud
                    prevention;
                  </li>
                  <li>
                    {' '}
                    The Company may disclose your information to such third
                    parties to whom it transfers its rights and duties under any
                    agreement entered into with such third parties; and
                  </li>
                  <li>
                    {' '}
                    The Company may disclose your information to any of its
                    affiliates or related entities.
                  </li>
                </ol>

                <h3 className='font-bold py-4'> 7. Sharing of Information</h3>
                <h4 className='font-normal text-base text-justify pt-4'>
                  7.1. Sharing
                </h4>
                <p className='font-normal text-base text-justify pt-4'>
                  The Company may share aggregated personal information with the
                  Company’s partners/clients as per the terms of any agreement
                  with such partner/client. The data shared may be on an
                  identified or unidentified basis, based on the terms agreed by
                  the Company with such partner/client. Information available
                  with the Company may be shared by the Company even after
                  completion of the Services. The Company shall not be liable
                  for the transfer of any personal identification information
                  resulting from the loss or distribution of data, the
                  delineation or corruption of storage media, power failures,
                  natural phenomena, riots, acts of vandalism, sabotage,
                  terrorism or any other event beyond the Company’s control.
                </p>
                <h4 className='font-normal text-base text-justify pt-4'>
                  7.2. Consulting and Sub Contracting
                </h4>

                <p className='font-normal text-base text-justify pt-4'>
                  The Company may sub-contract all or part of the Services to a
                  third-party sub-contractor, or partner with another party to
                  provide specific services. When you sign up for these
                  services, the Company will share names, or other contact
                  information that is necessary for the third party to provide
                  these services. Per the Company’s contractual arrangements
                  with parties, these parties are not allowed to use personally
                  identifiable information except for the explicit purpose of
                  providing these services.
                </p>

                <h3 className='font-bold py-4'> 8. Spam</h3>
                <p className='font-normal text-base text-justify pt-4'>
                  Spam The Company maintains a strict “No-Spam” policy, which
                  means that the Company does not intend to sell, rent, or
                  otherwise give your e-mail address to a third party without
                  your consent.
                </p>

                <h3 className='font-bold py-4'>9.Exclusion</h3>
                <ol className='list-decimal font-normal text-base text-justify px-6'>
                  <li>
                    This Privacy Policy does not apply to any information other
                    than information collected by the Company via any means,
                    including the Platform, including such information collected
                    in accordance with the clause on “Use of the Information
                    Collected” above. This Privacy Policy will not apply to any
                    unsolicited information provided by you through this
                    Platform or through any other means. This includes but is
                    not limited to, information posted on any public areas of
                    the Platform. All such unsolicited information shall be
                    deemed to be non-confidential and the Company will be free
                    to use, and disclose such unsolicited information without
                    limitation.
                  </li>
                  <li>
                    {' '}
                    The Company also protects your personal information offline
                    other than as specifically mentioned in this Privacy Policy.
                    Access to your personal information is limited to employees,
                    agents, consultants or partners and third parties, who the
                    Company reasonably believes will need that information to
                    enable the Company to provide Services to you. The Company
                    will make best efforts to ensure that your personal contact
                    information is protected. However, the Company is not
                    responsible for the confidentiality, security or
                    distribution of your personal information by our partners
                    and third parties outside the scope of our agreement with
                    such partners and third parties.
                  </li>
                </ol>

                <h3 className='font-bold py-4'>10.Confidentiality</h3>
                <p className='font-normal text-base text-justify pt-4'>
                  The Company takes all necessary precautions to protect your
                  personal information both online and offline. No administrator
                  at the Company will have knowledge of your password.
                </p>
                <h4 className='font-normal text-base text-justify pt-4'>
                  10.1 Third-party Links
                </h4>
                <p className='font-normal text-base text-justify pt-4'>
                  The Platform may contain links to other sites, products,
                  platforms and services. These are not necessarily under the
                  control of the Company. Please be aware that the Company is
                  not responsible for the privacy practices of such other sites.
                  The Company encourages you to read the privacy policies of
                  each and every website that collects personally identifiable
                  information. If you decide to access any of the third-party
                  sites linked to the Platform, you do this entirely at your own
                  risk. Any links to any partner of the Platform should be the
                  responsibility of the linking party, and the Company shall not
                  be responsible for notification of any change in name or
                  location of any information on the Platform. You further
                  acknowledge and agree that we are not liable for any loss or
                  damage which may be incurred by you as a result of the
                  collection and/or disclosure of your information via Third
                  Party Links, as a result of any reliance placed by you on the
                  completeness, accuracy or existence of any advertising,
                  products services, or other materials on, or available via
                  such Third Party Links. This will include all transactions,
                  and information transmitted therein, between you and any such
                  third-party sites or applications or resources, such
                  transactions are strictly bi-partite. We shall not be liable
                  for any disputes arising from or in connection with such
                  transactions between you and the aforementioned third parties.
                  We recommend that you exercise reasonable diligence, as you
                  would in traditional offline channels and practice judgment
                  and common sense before committing to any transaction or
                  exchange of information, including but not limited to
                  reviewing the third-party website or application’s privacy
                  policy.
                </p>

                <h3 className='font-bold py-4'>11. Notification of Changes</h3>
                <ul className='list-disc font-normal text-base text-justify px-6'>
                  <li>
                    We may update this Privacy Policy at any time, with or
                    without advance notice. In the event there are significant
                    changes in the way we treat your personally identifiable
                    information, or in the Privacy Policy document itself, we
                    will display a notice on the Company website or send you a
                    notification or update of the same through electronic means
                    either within or outside the Platform, so that you may
                    review the changed terms. As always, if you object to any of
                    the changes to our terms, and you no longer wish to use the
                    Platform, you may contact support@vigorushealthtech.com to
                    deactivate your account. Unless stated otherwise, our
                    current Privacy Policy applies to all information that the
                    Company has about you and your account.
                  </li>
                  <li>
                    Using the Company Services or accessing the Platform after a
                    notice of changes has been sent to you or published on our
                    website shall constitute your consent to the changed terms.
                  </li>
                </ul>
                <h3 className='font-bold py-4'>12.Consent To This Policy</h3>
                <p className='font-normal text-base text-justify'>
                  The Terms of Use Agreement is incorporated herein by reference
                  in its entirety.
                </p>

                <h3 className='font-bold py-4'>13.Grievance Officer </h3>
                <p className='font-normal text-base text-justify'>
                  If you have any grievance with respect to the Platform or the
                  Services, including any discrepancies and grievances with
                  respect to the processing of information, you can contact our
                  Grievance Officer at:
                  <a
                    className='underline'
                    href='mailto:support@vigorushealtech.com'
                  >
                    support@vigorushealtech.com
                  </a>
                </p>
                <p className='font-normal text-base text-justify'>
                  The Grievance Officer shall redress your grievances
                  expeditiously, within 1 (one) month from the date of receipt
                  of the grievance. Except where required by law, the Company
                  cannot ensure a response to questions or comments regarding
                  topics unrelated to this policy or the Company’s privacy
                  practices.
                </p>
                <h3 className='font-bold py-4'>
                  14.Contact person in data protection matters
                </h3>
                <p className='font-normal text-base text-justify '>
                  If you have any questions regarding the processing of your
                  personal data, please contact us via the following: Data
                  Protection Officer: Chirag Goyal E-mail:{' '}
                  <a
                    className='underline'
                    href='mailto:chirag@vigorushealth.com'
                  >
                    chirag@vigorushealth.com{' '}
                  </a>
                </p>

                <h3 className='font-bold py-4'>15.CONSENT</h3>
                <p className='font-normal text-base text-justify '>
                  By consenting to the terms under this Privacy Policy, you
                  hereby provide express consent to the Company to collect,
                  share, transfer, store, retain, disseminate or use the
                  information collected by the Company from your usage of the
                  Platform in accordance with the terms of the Privacy Policy.
                  The Company will continue to retain information provided by
                  you until you specifically request the Company to destroy such
                  information. You may, at any time, withdraw consent for the
                  collection or processing of any information provided by you,
                  by sending an email to support@vigorushealtech.com. Upon
                  verification of such request, the Company may, subject to its
                  obligations pursuant to law, destroy all information provided
                  by you from its servers. However, please note that the Company
                  shall continue to retain a copy of the information provided by
                  you for the purpose of carrying out periodic audits. In such a
                  case, the Company shall also instruct its partners/ clients to
                  delete any copy of your information. However, the Company
                  shall not have the obligation to validate or enforce any such
                  deletion by the partner/client.
                </p>
                <h3 className='font-bold py-4'>16.Miscellaneous</h3>
                <h4 className='font-normal text-base text-justify '>
                  16.1 Indemnity:
                </h4>
                <p className='font-normal text-base text-justify pt-4'>
                  You agree and undertake to indemnify us in any suit or dispute
                  by any third party arising out of disclosure of information by
                  you to third parties either through our Platform or otherwise
                  and your use and access of websites, applications and
                  resources of third parties. We assume no liability for any
                  actions of third parties with regard to your personal
                  information which you may have disclosed to such third
                  parties.
                </p>
                <h4 className='font-normal text-base text-justify pt-4'>
                  16.2 Severability:
                </h4>
                <p className='font-normal text-base text-justify pt-4'>
                  Each clause of this Privacy Policy shall be and remain
                  separate from and independent of and severable from all and
                  any other clauses herein except where otherwise expressly
                  indicated or indicated by the context of the Privacy Policy.
                  The decision or declaration that one or more clauses are null
                  and void shall have no effect on the remaining clauses of this
                  Privacy Policy.
                </p>
                <p className='font-normal text-base text-justify pt-4'>
                  You have read this Privacy Policy and agree to all of the
                  provisions contained above.
                </p>
              </div>
            </DialogDescription>
          </div>
        </DialogHeader>
        <DialogFooter className='border-t border-border px-6 py-4 sm:items-center'>
          {!hasReadToBottom && (
            <span className='grow text-xs text-muted-foreground max-sm:text-center'>
              Read all terms before accepting.
            </span>
          )}
          <DialogClose asChild>
            <Button type='button' variant='outline'>
              Cancel
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button type='button' disabled={!hasReadToBottom}>
              I agree
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
