'use client';

import axios from 'axios';
import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Globe } from '@/components/ui/globe';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    query: '',
  });
  const [status, setStatus] = useState('idle');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('submitting');

    try {
      const response = await axios.post('/api/contact', formData);

      if (response.status === 200) {
        setStatus('submitted');
        setFormData({ name: '', mobile: '', email: '', query: '' });
      } else {
        setStatus('error');
      }
    } catch (error) {
      setStatus('error');
      console.error(error);
    }
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className='container mx-auto'>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-8 items-center'>
        <div className='hidden md:block'>
          <Globe />
        </div>

        <div className='space-y-6 p-2 mt-9  rounded-lg'>
          <h4 className='text-3xl lg:text-5xl lg:leading-tight max-w-5xl mx-auto text-center tracking-tight font-medium text-black dark:text-white '>
            Contact Us
          </h4>
          <form className='space-y-4' onSubmit={handleSubmit}>
            <div className='space-y-2'>
              <Input
                placeholder='Your Name'
                name='name'
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className='space-y-2'>
              <Input
                type='tel'
                placeholder='Mobile Number'
                name='mobile'
                value={formData.mobile}
                onChange={handleChange}
                required
              />
            </div>
            <div className='space-y-2'>
              <Input
                type='email'
                placeholder='Email Address'
                name='email'
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className='space-y-2'>
              <Textarea
                placeholder='Your Query'
                rows={4}
                name='query'
                value={formData.query}
                onChange={handleChange}
                required
              />
            </div>
            <Button
              type='submit'
              className='w-full'
              disabled={status === 'submitting'}
            >
              {status === 'submitting'
                ? 'Submitting...'
                : status === 'submitted'
                  ? 'Submitted!'
                  : 'Submit'}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}
