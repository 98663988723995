import(/* webpackMode: "eager" */ "/codebuild/output/src2243533541/src/chikitsa-ai-website/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["BackedBy"] */ "/codebuild/output/src2243533541/src/chikitsa-ai-website/src/app/(main)/components/BackedBy.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Blogs"] */ "/codebuild/output/src2243533541/src/chikitsa-ai-website/src/app/(main)/components/blogs/Blogs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2243533541/src/chikitsa-ai-website/src/app/(main)/components/contact-us/ContactUs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/codebuild/output/src2243533541/src/chikitsa-ai-website/src/app/(main)/components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Hero"] */ "/codebuild/output/src2243533541/src/chikitsa-ai-website/src/app/(main)/components/Hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2243533541/src/chikitsa-ai-website/src/app/(main)/components/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2243533541/src/chikitsa-ai-website/src/app/(main)/components/OurSolutions.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProblemStatement"] */ "/codebuild/output/src2243533541/src/chikitsa-ai-website/src/app/(main)/components/Problem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TechPartnenrs"] */ "/codebuild/output/src2243533541/src/chikitsa-ai-website/src/app/(main)/components/TechPartners.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Testimonials"] */ "/codebuild/output/src2243533541/src/chikitsa-ai-website/src/app/(main)/components/Testimonials.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WhyWeStandOut"] */ "/codebuild/output/src2243533541/src/chikitsa-ai-website/src/app/(main)/components/WeStandOut.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NumberTicker"] */ "/codebuild/output/src2243533541/src/chikitsa-ai-website/src/components/ui/number-ticker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Timeline"] */ "/codebuild/output/src2243533541/src/chikitsa-ai-website/src/components/ui/timeline.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TracingBeam"] */ "/codebuild/output/src2243533541/src/chikitsa-ai-website/src/components/ui/tracing-beam.tsx");
