'use client';

import { useQuery } from '@tanstack/react-query';
import { Card, Carousel } from '@/components/ui/apple-cards-carousel';
import { fetchBlogs } from './fetchBlogs';

export const Blogs = () => {
  const { data: blogs } = useQuery({
    queryKey: ['blogs'],
    queryFn: fetchBlogs,
  });
  // console.log(blogs, 'blogs');

  let formattedCards = [];
  if (blogs) {
    formattedCards = blogs?.data?.map(
      (item: {
        yoast_head_json: {
          title: string;
          author: string;
          og_image: { url: string }[];
        };
        content: { rendered: string };
        link: string;
      }) => ({
        src: item.yoast_head_json?.og_image?.[0]?.url,
        title: item?.yoast_head_json?.title,
        category: item?.yoast_head_json?.author,
        content: item.content.rendered,
        link: item.link || '#',
      }),
    );
  }
  const cards = formattedCards?.map(
    (
      card: {
        src: string;
        title: string;
        category: string;
        content: string;
        link: string;
      },
      index: number,
    ) => <Card key={index} card={card} index={index} />,
  );

  return (
    <div className='w-full flex flex-col items-center justify-center'>
      <div className='flex items-center justify-center gap-4 w-full max-w-5xl mx-auto pt-10 pb-5'>
        <div className='h-[2px] bg-slate-300 dark:bg-white flex-1'></div>
        <h4 className='text-3xl lg:text-5xl lg:leading-tight text-center tracking-tight font-medium text-black dark:text-white'>
          Blogs
        </h4>
        <div className='h-[2px] bg-slate-300 dark:bg-white flex-1'></div>
      </div>
      <Carousel items={cards} showFooter={false} />
    </div>
  );
};
