'use client';

import Image from 'next/image';
import React from 'react';
import { Card, CardContent } from '@/components/ui/card';

const investors = [
  {
    id: 1,
    name: 'IIM Kashipur',
    designation: '',
    image: '/investors/iim.svg',
  },
  {
    id: 2,
    name: 'AIC Banasthali',
    designation: '',
    image: '/investors/vanasthali.svg',
  },
  {
    id: 3,
    name: 'IIT Roorkee',
    designation: '',
    image: '/investors/iitr.svg',
  },
  {
    id: 4,
    name: 'BITS Pilani',
    designation: '',
    image: '/investors/bitsp.svg',
  },
  {
    id: 5,
    name: 'IITI DRISHTI',
    designation: '',
    image: '/investors/drishti.svg',
  },
  {
    id: 6,
    name: 'iStart',
    designation: '',
    image: '/investors/istart.svg',
  },
  {
    id: 7,
    name: 'IIMA Ventures',
    designation: '',
    image: '/investors/iima.svg',
  },
  {
    id: 8,
    name: 'IIT Indore',
    designation: '',
    image: '/investors/iitindore.svg',
  },
];

export const BackedBy = () => {
  //   const chunkArray = (arr: typeof investors, size: number) => {
  //     return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
  //       arr.slice(i * size, i * size + size),
  //     );
  //   };

  return (
    <div className='px-2 pt-8'>
      <h4 className='text-3xl lg:text-5xl lg:leading-tight max-w-5xl mx-auto text-center tracking-tight font-medium text-black dark:text-white'>
        Backed By
      </h4>
      <div className='grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-4 max-w-4xl mx-auto mt-4 mb-10 px-4'>
        {investors.map((investor) => (
          <Card
            key={investor.id}
            className='flex items-center justify-center border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05] dark:border-gray-50/[.1] dark:bg-gray-100/[.4] dark:hover:bg-gray-100/[.5]'
          >
            <CardContent className='p-6'>
              <Image
                src={investor.image}
                alt={investor.name}
                width={64}
                height={64}
                className='h-24 w-24 object-contain'
              />
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};
