'use client';

import { Home, Phone, User2 } from 'lucide-react';
import { FloatingNav } from '@/components/ui/floating-navbar';

const Navbar = () => {
  const navItems = [
    {
      name: 'Home',
      link: '/',
      icon: <Home className='h-4 w-4 text-neutral-500 dark:text-white' />,
    },
    {
      name: 'Services',
      link: '/#services',
      icon: <User2 className='h-4 w-4 text-neutral-500 dark:text-white' />,
    },
    {
      name: 'Contact',
      link: '/#contact',
      icon: <Phone className='h-4 w-4 text-neutral-500 dark:text-white' />,
    },
  ];

  return <FloatingNav navItems={navItems} />;
};

export default Navbar;
